import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { envVariables, versions } from '@kenv';
import { environment } from '@kp/../environments/environment';
import { DataStoreService, WINDOW } from '@kservice';
import { HttpStatusCode } from '@ktypes/enums';
import { JsonObject } from '@ktypes/models';
import { captureException, init, withScope } from '@sentry/angular';
import { ErrorEvent, Event, Integration } from '@sentry/types';
import { DeviceDetectorService } from 'ngx-device-detector';

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
init({
  dsn: 'https://297acee3f8b8402b8146eb2a6b79b0a9@sentry.io/4616741',
  environment: environment?.environment,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  release: versions?.app?.[envVariables?.['PROJECT'] ?? 'purposeful'] as string,
  beforeSend(event: ErrorEvent) {
    // This is to maintain the dev filter for Angular tests which do not run through handleError
    const regex = RegExp(/localhost|127.0.0.1|0.0.0.0|10.0.*|192.168.*/g);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (environment.environment !== 'dev' && !regex.test(event.request.url)) {
      return event as Event;
    }
    return null;
  },
  integrations: (integrations: Integration[]) =>
    // Exclude the following from integrations
    integrations.filter((integration) => !['BrowserTracing', 'Replay'].includes(integration.name)),
});

@Injectable({
  providedIn: 'root',
})
export class CustomErrorHandler implements ErrorHandler {
  constructor(
    private _dataStoreService: DataStoreService,
    private _deviceDetectorService: DeviceDetectorService,
    private _ngZone: NgZone,
    private _router: Router,
    @Inject(WINDOW) private _window: Window
  ) {}

  _console: Console = console;

  handleError(error: any) {
    const originalError = findOriginalError(error);
    const context = findContext(error);
    // Note: Browser consoles show the place from where console.warn was called.
    // We can use this to give users additional information about the error.
    const errorLogger = getErrorLogger(error);
    const chunkFailedMessage = /Loading chunk \d+ failed/;

    // Check for error and send to Sentry if not DEV environment
    const browser = this._deviceDetectorService.getDeviceInfo().browser;
    const url = this._window.location.origin;
    const regex = RegExp(/localhost|127.0.0.1|0.0.0.0|10.0.*|192.168.*/g);

    // If this is a Chunk load error, reload the page
    if (chunkFailedMessage.test((error as Error).message)) {
      this._window.location.reload();
    }

    // If not on DEV, send issue to Sentry
    if (environment.environment !== 'dev' && browser !== 'IE' && !regex.test(url)) {
      const groupCode = this._dataStoreService.authData?.user?.group?.groupCode;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      withScope((scope) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        scope.setExtra('groupCode', groupCode);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        captureException(
          (error && (error as JsonObject).originalError) || error || new Error('Error was null or undefined')
        );
      });
    }
    // if not sending to Sentry, log to the console as an error
    else {
      errorLogger(this._console, `ERROR`, error);
      if (originalError) {
        errorLogger(this._console, `ORIGINAL ERROR`, originalError);
      }
      if (context) {
        errorLogger(this._console, 'ERROR CONTEXT', context);
      }
    }

    if (
      (error as HttpErrorResponse).status &&
      ![
        HttpStatusCode.BAD_REQUEST,
        HttpStatusCode.UNAUTHORIZED,
        HttpStatusCode.PAYMENT_REQUIRED,
        HttpStatusCode.FORBIDDEN,
        HttpStatusCode.NOT_FOUND,
      ].includes((error as HttpErrorResponse).status)
    ) {
      void this._ngZone.run(() => this._router.navigate(['error']));
    }
  }
}

export const ERROR_DEBUG_CONTEXT = 'ngDebugContext';
export const ERROR_ORIGINAL_ERROR = 'ngOriginalError';
export const ERROR_LOGGER = 'ngErrorLogger';

function findContext(error: Error): Error {
  if (error) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return getDebugContext(error) ? getDebugContext(error) : findContext(getOriginalError(error));
  }

  return null;
}

function findOriginalError(error: Error): Error {
  let e = getOriginalError(error);
  while (e && getOriginalError(e)) {
    e = getOriginalError(e);
  }

  return e;
}

function getDebugContext(error: Error) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
  return (error as any)[ERROR_DEBUG_CONTEXT];
}

function getOriginalError(error: Error): Error {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
  return (error as any)[ERROR_ORIGINAL_ERROR];
}

function getErrorLogger(error: Error): (console: Console, ...values: any[]) => void {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
  return (error as any)[ERROR_LOGGER] || defaultErrorLogger;
}

function defaultErrorLogger(console: Console, ...values: any[]) {
  console.error(...values);
}
