import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { SocialChallengeCompletion } from '@kf-sc';
import { SearchBloc } from '@kp/search/search.bloc';
import { Search } from '@kp/search/search.model';
import { CardCollectionService } from '@kp/shared/components/cards/card-collection.service';
import { CardApi } from '@kp/shared/components/cards/card.api';
import { DailyChallengeStatusBloc } from '@kp/shared/components/daily-challenge/daily-challenge-status.bloc';
import { SharedConstants } from '@kservice';
import {
  CardDisplayType,
  CardEventType,
  CardItemState,
  CardRequestType,
  CardScreen,
  CustomCardCategory,
  HttpStatusCode,
} from '@ktypes/enums';
import {
  CardCollection,
  CardEvent,
  CardItem,
  DataStatus,
  DetailCardData,
  DialogueDeepLinkData,
  Habit,
  JsonObject,
  LocalResourcesProgram,
  NotificationInfo,
  Quest,
  Status,
  StatusMessage,
  UserCardPreference,
  UserCardState,
  getTypedDeepLinkData,
} from '@ktypes/models';
import { DateTimeUtil, assertIsOfType, isOfType } from '@kutil';
import remove from 'lodash/remove';
import { BehaviorSubject, Observable, Subject, auditTime, buffer, of, share } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';

export enum CardType {
  action = 'action',
  quest = 'quest',
}

export type DomainFilteredCardCollection = {
  [domain: string]: {
    [filterCardRequestType: string]: BehaviorSubject<DataStatus<CardCollection[]>>;
  };
};

@Injectable({
  providedIn: 'root',
})
export class CardBloc {
  constructor(
    private _cardApi: CardApi,
    private _cardCollectionService: CardCollectionService,
    private _challengeCompletionService: SocialChallengeCompletion,
    private _dailyChallengeStatusBloc: DailyChallengeStatusBloc, // TODO: Eliminate cross-bloc dependency
    private _environmentVariablesService: EnvironmentVariablesService,
    private _location: Location,
    private _searchBloc: SearchBloc // TODO: Eliminate cross-bloc dependency
  ) {
    const batchedEvents$ = this._batchCardEvents$.pipe(share());
    batchedEvents$
      .pipe(buffer(batchedEvents$.pipe(auditTime(SharedConstants.BATCH_THROTTLE_TIME))))
      .subscribe((events: CardEvent[]) => {
        if (events?.length > 0) {
          void this._cardApi.createCardEvents(events);
        }
      });
  }

  private _batchCardEvents$ = new Subject<CardEvent>();
  // TODO: refactor?
  private _cardSubjects = new Map([
    [CardRequestType.AVAILABLE, new BehaviorSubject<DataStatus<CardCollection[]>>(null)],
    [CardRequestType.DOMAIN_ACTION, new BehaviorSubject<DataStatus<CardCollection[]>>(null)],
    [CardRequestType.DOMAIN_AVAILABLE, new BehaviorSubject<DataStatus<CardCollection[]>>(null)],
    [CardRequestType.QUEST, new BehaviorSubject<DataStatus<CardCollection[]>>(null)],
    [CardRequestType.REFLECTION, new BehaviorSubject<DataStatus<CardCollection[]>>(null)],
    [CardRequestType.RESOURCES, new BehaviorSubject<DataStatus<CardCollection[]>>(null)],
    [CardRequestType.RECOMMENDED, new BehaviorSubject<DataStatus<CardCollection[]>>(null)],
    [CardRequestType.SAVED, new BehaviorSubject<DataStatus<CardCollection[]>>(null)],
    [CardRequestType.SEARCH, new BehaviorSubject<DataStatus<CardCollection[]>>(null)],
    [CardRequestType.SOLO, new BehaviorSubject<DataStatus<CardCollection[]>>(null)],
    [CardRequestType.TAKE_ACTION, new BehaviorSubject<DataStatus<CardCollection[]>>(null)],
    [CardRequestType.TRACKING, new BehaviorSubject<DataStatus<CardCollection[]>>(null)],
    [CardRequestType.TOTD, new BehaviorSubject<DataStatus<CardCollection[]>>(null)],
  ]);
  private _cardsToRemove: CardItem[] = [];
  private _cardUserWithoutAccountAttemptedToSave = new BehaviorSubject<CardItem | LocalResourcesProgram>(null);
  private _currentCompletionCardMapByKey$ = new BehaviorSubject<{
    [key: string]: CardItem;
  }>({});
  private _firstCardAdded = new Subject<CardType>();
  private _showAccountCreationModal = new BehaviorSubject<boolean>(false);
  private _tipOfTheDayCard = new BehaviorSubject<DataStatus<CardItem>>(null);

  clickEnabled = new Subject<boolean>();
  savedApiCallFailed = new Subject();

  // exposed publicly to allow for modal to be controlled in app component
  detailViewClicked = new BehaviorSubject<DetailCardData>(null);
  detailViewClosed = new Subject<boolean>();

  private _domainFilteredCardCollections$: DomainFilteredCardCollection = {};

  // Get access to the stream of authentication events
  get availableCollection$(): Observable<DataStatus<CardCollection[]>> {
    return this._cardSubjects.get(CardRequestType.AVAILABLE).asObservable();
  }

  get domainAvailableCollection$(): Observable<DataStatus<CardCollection[]>> {
    return this._cardSubjects.get(CardRequestType.DOMAIN_AVAILABLE).asObservable();
  }

  get trackingCollection$(): Observable<DataStatus<CardCollection[]>> {
    return this._cardSubjects.get(CardRequestType.TRACKING).asObservable();
  }

  get resourcesCollection$(): Observable<DataStatus<CardCollection[]>> {
    return this._cardSubjects.get(CardRequestType.RESOURCES).asObservable();
  }

  get recommendedCollection$(): Observable<DataStatus<CardCollection[]>> {
    return this._cardSubjects.get(CardRequestType.RECOMMENDED).asObservable();
  }

  get takeActionCollection$(): Observable<DataStatus<CardCollection[]>> {
    return this._cardSubjects.get(CardRequestType.TAKE_ACTION).asObservable();
  }

  get domainActionCollection$(): Observable<DataStatus<CardCollection[]>> {
    return this._cardSubjects.get(CardRequestType.DOMAIN_ACTION).asObservable();
  }

  get detailViewCard$(): Observable<DetailCardData> {
    return this.detailViewClicked.asObservable();
  }

  get detailViewClosed$(): Observable<boolean> {
    return this.detailViewClosed.asObservable();
  }

  get showAccountCreationModal$(): Observable<boolean> {
    return this._showAccountCreationModal.asObservable();
  }

  get currentCompletionCardMapByKey() {
    return this._currentCompletionCardMapByKey$.getValue();
  }

  get hasCompletedCards$(): Observable<boolean> {
    return this.completedCards$.pipe(
      map((completedCards) => {
        return (
          Object.values(completedCards?.cardItems || {}).filter((card) => !card.hideFromUI).length > 0 ||
          Object.values(this._cardCollectionService.cardsById || {}).filter(
            (card: CardItem) => card.userState.isCompleted
          ).length > 0
        );
      })
    );
  }

  get actionCards$(): Observable<DataStatus<CardCollection>> {
    return this.trackingCollection$.pipe(
      map((collection) => {
        if (collection?.status === Status.starting) {
          return new DataStatus<CardCollection>(Status.starting, null, null);
        } else if (collection?.data?.[0]?.cardItems) {
          const listCardCollection = collection.data;
          return new DataStatus<CardCollection>(
            Status.done,
            null,
            this._filterCollection(listCardCollection[0], false, 'My Actions', true)
          );
        }
        return null;
      })
    );
  }

  get questCards$() {
    return this.trackingCollection$.pipe(
      map((collection) => {
        if (collection?.status === Status.starting) {
          return new DataStatus<CardCollection>(Status.starting, null, null);
        } else if (collection?.data?.[0]?.cardItems) {
          const listCardCollection = collection.data;
          return new DataStatus<CardCollection>(
            Status.done,
            null,
            this._filterCollection(listCardCollection[1], false, 'My Quests', true)
          );
        }
        return null;
      })
    );
  }

  get singleTrackingCollection$(): Observable<DataStatus<CardCollection>> {
    return this.trackingCollection$.pipe(
      map((collection) => {
        if (collection?.status === Status.starting) {
          return new DataStatus<CardCollection>(Status.starting, null, null);
        } else if (collection?.data) {
          const listCardCollection = collection.data;
          const cardIds = [
            ...Object.keys(listCardCollection?.[0]?.cardItems),
            ...Object.keys(listCardCollection?.[1]?.cardItems),
          ];
          return new DataStatus<CardCollection>(
            Status.done,
            null,
            new CardCollection().deserialize({
              name: 'Action Reminders',
              description: 'Action Reminders description',
              cardItems: Object.values(this._cardCollectionService.cardsById || {})
                .filter((card) => cardIds?.includes(card?.id))
                .reduce((acc: JsonObject, cardItem) => {
                  acc[cardItem.id] = cardItem;
                  return acc;
                }, {}),
            })
          );
        }
        return null;
      })
    );
  }

  get completedCards$() {
    return this.trackingCollection$.pipe(
      map((collection) => {
        if (collection?.data?.[0]?.cardItems) {
          const listCardCollection = collection.data;
          return this._filterCollection(listCardCollection[0], true, 'Completed Actions');
        }
        return null;
      })
    );
  }

  get firstCardStatus$(): Observable<CardType> {
    return this._firstCardAdded.asObservable();
  }

  get savedCardCollection$(): Observable<DataStatus<CardCollection[]>> {
    return this._cardSubjects.get(CardRequestType.SAVED).asObservable();
  }

  get savedCards$(): Observable<DataStatus<CardCollection>> {
    return this.savedCardCollection$.pipe(
      map((collection) => {
        if (collection?.status === Status.starting) {
          return new DataStatus<CardCollection>(Status.starting, null, null);
        } else if (collection?.data?.[0]?.cardItems) {
          const listCardCollection = collection.data;
          return new DataStatus<CardCollection>(Status.done, null, this._filterSavedCards(listCardCollection?.[0]));
        }
        return null;
      })
    );
  }

  get tipOfTheDayCardStatus$(): Observable<DataStatus<CardItem>> {
    return this._tipOfTheDayCard.asObservable();
  }
  get tipOfTheDayCard$(): Observable<CardItem> {
    return this._tipOfTheDayCard.pipe(
      map((totdCardStatus) => totdCardStatus?.status === Status.done && totdCardStatus?.data)
    );
  }

  domainFilteredCardCollections$(
    domainKey: string,
    cardRequestType: CardRequestType
  ): Observable<DataStatus<CardCollection[]>> {
    if (this._domainFilteredCardCollections$?.[domainKey]?.[cardRequestType] == null) {
      // send an empty array back if that domainKey/cardRequestType combo has not been saved yet
      return of(new DataStatus<CardCollection[]>(Status.done, []));
    }
    return this._domainFilteredCardCollections$?.[domainKey]?.[cardRequestType]?.pipe(
      filter((cardCollections) => cardCollections != null),
      distinctUntilChanged()
    );
  }

  getCardByContentId(contentId: string, screen: CardScreen, customCardCategory?: CustomCardCategory) {
    // Look for the card locally
    const card = this._getMatchingCard({ card: new CardItem().deserialize({ id: contentId }) });

    if (card) {
      assertIsOfType<CardItem, LocalResourcesProgram>(card, 'isRepeatable');
      this.detailViewClicked.next({
        card: card,
        customCardCategory,
        detailViewClicked: true,
        requestType: CardRequestType.SOLO,
        screen: screen,
      });
      return;
    }

    // Fetch the card from API as a backup
    this._cardApi.getCard(DateTimeUtil.formatInLocal(), contentId).then((cardItem) => {
      if (cardItem) {
        if (!cardItem.id) {
          cardItem.id = contentId;
        }
        if (!cardItem.userState || !cardItem.userState?.saveState) {
          cardItem.userState = new UserCardState();
        }

        this._updateCardCollection(CardRequestType.SOLO, false, cardItem);

        // open modal
        this.detailViewClicked.next({
          card: cardItem,
          customCardCategory,
          detailViewClicked: true,
          requestType: CardRequestType.SOLO,
          screen: screen,
        });
      } else {
        // If the card cannot be found, remove the cardId from the route
        if (screen === CardScreen.saved || screen === CardScreen.category) {
          this._location.go(`/cards/${customCardCategory}`);
        } else {
          this._location.go(`/${screen}`);
          console.warn('card not found, resetting route to referring screen');
        }
      }
    });
  }

  async handleCardEvents(event: CardEvent, batch = true) {
    // NOTE: this method is async because COMPLETE/QUEST_CARD_COMPLETE events must be awaited :(
    if (event != null) {
      if (!event.eventInfo) {
        event.eventInfo = {};
      }
      event.eventInfo.product = this._environmentVariablesService.product;
      const card = this._getMatchingCard(event);
      let shouldUpdateList = true;
      if (card) {
        switch (event.type) {
          case CardEventType.TRACK: {
            assertIsOfType<CardItem, LocalResourcesProgram>(card, 'isActionable');
            await this._handleTrackAPIEvent(event, card);
            const refreshTypes = [];
            if (event.requestType === CardRequestType.TOTD) {
              refreshTypes.push(CardRequestType.TOTD);
            }
            this.refresh(refreshTypes, null);
            break;
          }
          case CardEventType.UNTRACK: {
            assertIsOfType<CardItem, LocalResourcesProgram>(card, 'isActionable');
            await this._handleTrackAPIEvent(event, card);
            this._handleUpdateChallengeStatus();
            const refreshTypes = [];
            if (event.requestType === CardRequestType.TOTD) {
              refreshTypes.push(CardRequestType.TOTD);
            }
            this.refresh(refreshTypes, null);
            break;
          }
          case CardEventType.OPEN:
          case CardEventType.CLOSE:
          case CardEventType.DISCOVER_VIEW:
          case CardEventType.QUEST_CARD_OPEN:
          case CardEventType.QUEST_CARD_CLOSE:
          case CardEventType.QUEST_CARD_VIEW:
          case CardEventType.SEARCH_OPEN:
          case CardEventType.SEARCH_VIEW:
            this._handleInteractionAPIEvent(event, batch);
            shouldUpdateList = false;
            break;
          case CardEventType.LESS:
            this._handleLessAPIEvent(event, card, batch);
            this._handleUpdateChallengeStatus();
            break;
          case CardEventType.MORE:
            this._handleMoreAPIEvent(event, card, batch);
            this._handleUpdateChallengeStatus();
            break;
          case CardEventType.COMPLETE:
          case CardEventType.QUEST_CARD_COMPLETE:
            assertIsOfType<CardItem, LocalResourcesProgram>(card, 'isActionable');
            // call before handle complete because we have what we need locally, so can optimistically update
            this._challengeCompletionService.checkForCompletion(card, event.type);
            await this._handleCompleteAPIEvent(event, card, batch);
            this._handleUpdateChallengeStatus();
            break;
          case CardEventType.UNCOMPLETE:
            assertIsOfType<CardItem, LocalResourcesProgram>(card, 'isActionable');
            await this._handleUncompleteAPIEvent(event, card);
            // call after handle uncomplete because we don't have what we need locally, so can't optimistically update
            this._challengeCompletionService.checkForCompletion(card, event.type);
            this._handleUpdateChallengeStatus();
            break;
          case CardEventType.ANIMATION_COMPLETE:
            assertIsOfType<CardItem, LocalResourcesProgram>(card, 'isActionable');
            this._handleAnimationCompleteUIEvent(card);
            break;
          case CardEventType.RESET_ERROR:
            assertIsOfType<CardItem, LocalResourcesProgram>(card, 'isActionable');
            //connected but not implemented yet
            this._handleResetErrorUIEvent(event, card);
            break;
          case CardEventType.SAVED:
            card.userState.saveState.isSaved = true;
            break;
          case CardEventType.UNSAVED:
            card.userState.saveState.isSaved = false;
            break;
        }
        this._updateCardCollection(
          event.requestType,
          event.requestType === CardRequestType.SOLO &&
            [CardEventType.TRACK, CardEventType.UNTRACK].includes(event.type),
          event.card
        );
        if (this._cardSubjects.get(event.requestType)?.value?.data != null && shouldUpdateList) {
          this._publishUpdatedListToStream(this._cardSubjects.get(event.requestType).value.data, event.requestType);
          if (event.requestType === CardRequestType.TOTD) {
            assertIsOfType<CardItem, LocalResourcesProgram>(card, 'id');
            this._tipOfTheDayCard.next(new DataStatus(Status.done, new StatusMessage(HttpStatusCode.OK, 'OK'), card));
          }
          if (this.detailViewClicked.value?.detailViewClicked && this.detailViewClicked.value?.card) {
            // update the detail card if open after a card event
            assertIsOfType<CardItem, LocalResourcesProgram>(card, 'isRepeatable');
            this.detailViewClicked.next({ ...this.detailViewClicked.value, card });
          }
        }
      } else {
        console.log('No card(s) matched for handleCardEvents');
      }
    } else {
      console.log('No event for handleCardEvents');
    }
  }

  private _addToBatchEvents(event: CardEvent) {
    if (event) {
      this._batchCardEvents$.next(event);
    } else {
      console.log('No event for batching');
    }
  }

  async sendCardCompletionIfExistsInMap(completionCard: CardItem, cardRequestType: CardRequestType): Promise<boolean> {
    // NOTE: this method is async because COMPLETE/QUEST_CARD_COMPLETE events must be awaited :(
    if (Object.keys(this.currentCompletionCardMapByKey || {}).length > 0 && completionCard != null) {
      // Needed for domain actions after completing reflect and quest cards
      if (completionCard.isActionableAndNotTracking) {
        await this.handleCardEvents(new CardEvent(null, completionCard, CardEventType.TRACK, cardRequestType), false);
      }
      // NOTE: in general we don't prefer to await this event, but it needs to fire synchronously
      //       and there is no other way to know when the event completes as it is now
      await this.handleCardEvents(
        new CardEvent(
          null,
          completionCard,
          cardRequestType === CardRequestType.QUEST ? CardEventType.QUEST_CARD_COMPLETE : CardEventType.COMPLETE,
          cardRequestType
        ),
        false
      );
      return true;
    }
    return false;
  }

  refresh(
    requestType: CardRequestType | CardRequestType[],
    refKey?: string,
    domainKey?: string,
    backgroundUpdate = false
  ) {
    const normalizedRequestType = !Array.isArray(requestType) ? [requestType] : requestType;
    //giving loading state an empty collection to enable shimmer during loading
    normalizedRequestType.forEach((requestType) => {
      if (!backgroundUpdate) {
        const startingStatus = new DataStatus<CardCollection[]>(Status.starting, null, [
          new CardCollection(null, null, null),
        ]);
        this._cardSubjects.get(requestType).next(startingStatus);
        if (domainKey) {
          this._setDomainFilteredCardCollections(domainKey, requestType, startingStatus);
        }
      }
      if (requestType === CardRequestType.TAKE_ACTION) {
        this._cardApi.getCards(CardRequestType.DOMAIN_ACTION, DateTimeUtil.formatInLocal()).then((cardCollection) => {
          this._updateCardCollection(requestType, true, cardCollection, domainKey);
        });
      } else if (requestType === CardRequestType.TOTD) {
        this.getTipOfTheDayCard();
      } else {
        this._cardApi.getCards(requestType, DateTimeUtil.formatInLocal(), refKey, domainKey).then((cardCollection) => {
          this._updateCardCollection(requestType, true, cardCollection, domainKey);
        });
      }
    });
  }

  completeQuestCard(card: CardItem, quest: Quest): Promise<void> {
    const eventInfo: JsonObject = {};
    eventInfo['quest_presented_id'] = quest.presentedId;

    const event: CardEvent = new CardEvent(
      card.id,
      card,
      CardEventType.QUEST_CARD_COMPLETE,
      CardRequestType.QUEST,
      eventInfo
    );
    return this.handleCardEvents(event, false);
  }

  addCompletionCard(card: CardItem) {
    if (card) {
      const currentCompletionCardMapByKey = this.currentCompletionCardMapByKey;
      currentCompletionCardMapByKey[getTypedDeepLinkData<DialogueDeepLinkData>(card?.cardLink?.deepLinkData)?.refKey] =
        card;
      this._currentCompletionCardMapByKey$.next(currentCompletionCardMapByKey);
    }
  }

  removeCompletionCard(card: CardItem) {
    if (card) {
      const currentCompletionCardMapByKey = this.currentCompletionCardMapByKey;
      if (
        currentCompletionCardMapByKey[
          getTypedDeepLinkData<DialogueDeepLinkData>(card?.cardLink?.deepLinkData)?.refKey
        ] != null
      ) {
        delete currentCompletionCardMapByKey[
          getTypedDeepLinkData<DialogueDeepLinkData>(card?.cardLink?.deepLinkData).refKey
        ];
      }
      this._currentCompletionCardMapByKey$.next(currentCompletionCardMapByKey);
    }
  }

  firstCardAdded(cardType: CardType) {
    this._firstCardAdded.next(cardType);
  }

  saveCard(
    card: CardItem | LocalResourcesProgram,
    screen: CardScreen,
    requestType: CardRequestType,
    cardType: CardDisplayType,
    zip: string
  ): void {
    const id = chooseOurIdFromDifferentlyStructuredObjects(card);
    void this.handleCardEvents(new CardEvent(id, card, CardEventType.SAVED, requestType));
    this._cardApi.saveCard(id, screen, cardType, zip).then((response) => {
      if (response?.status === Status.error) {
        void this.handleCardEvents(new CardEvent(id, card, CardEventType.UNSAVED, requestType));
        this.savedApiCallFailed.next(true);
      }
    });
  }

  deleteSavedCard(card: CardItem | LocalResourcesProgram, requestType: CardRequestType) {
    const id = chooseOurIdFromDifferentlyStructuredObjects(card);
    void this.handleCardEvents(new CardEvent(id, card, CardEventType.UNSAVED, requestType));
    void this._cardApi.deleteSavedCard(id);
  }

  setShowAccountCreationModal(show: boolean) {
    this._showAccountCreationModal.next(show);
  }

  storeCardUserWithoutAccountAttemptedToSave(card: CardItem | LocalResourcesProgram) {
    this._cardUserWithoutAccountAttemptedToSave.next(card);
  }

  handlePotentialSavedCard() {
    if (this._cardUserWithoutAccountAttemptedToSave.value !== null) {
      this.saveCard(
        this._cardUserWithoutAccountAttemptedToSave.value,
        CardScreen.resources,
        CardRequestType.RESOURCES,
        CardDisplayType.compact,
        null
      );
      this._cardUserWithoutAccountAttemptedToSave.next(null);
    }
  }

  getReturnUrlFromCardScreen(screen: CardScreen, url = '/'): string {
    switch (screen) {
      case CardScreen.category:
        // TODO: Actions is the only page at the moment where a reflection from the category screen is possible.
        // We'll need to find a better way to get the return url if we add more categories in the future.
        return '/cards/actions';
      case CardScreen.quest:
        return url;
      case CardScreen.saved:
        return '/cards/saved';
      case CardScreen.discover:
        return '/discover';
      case CardScreen.resources:
        return '/resources';
      case CardScreen.recommended:
      case CardScreen.today:
        return '/today';
      default:
        return url;
    }
  }

  getTipOfTheDayCard(date?: Date | string) {
    this._tipOfTheDayCard.next(new DataStatus(Status.starting, null, null));

    // Format date as string if Date
    const formattedDate = date instanceof Date ? DateTimeUtil.formatDate(date) : date;
    this._cardApi
      .getTipOfTheDayCard(formattedDate)
      .then((cardItem) => {
        if (cardItem) {
          if (!cardItem.userState || !cardItem.userState?.saveState) {
            cardItem.userState = new UserCardState();
          }
          this._tipOfTheDayCard.next(new DataStatus(Status.done, new StatusMessage(HttpStatusCode.OK, 'OK'), cardItem));
          this._updateCardCollection(CardRequestType.TOTD, false, cardItem);
        } else {
          const errorMessage = 'Error - Tip of the Day not found';
          // no Tip of the Day found
          console.warn(errorMessage);
          this._tipOfTheDayCard.next(
            new DataStatus(Status.error, new StatusMessage(HttpStatusCode.NOT_FOUND, errorMessage), null)
          );
        }
      })
      .catch((error: HttpErrorResponse) => {
        console.warn(`Error getting Tip of the Day: ${error}`);
        this._tipOfTheDayCard.next(
          new DataStatus(
            Status.error,
            new StatusMessage(
              error?.status ?? HttpStatusCode.BAD_REQUEST,
              error?.message ?? 'Error getting Tip of the Day'
            ),
            null
          )
        );
      });
  }

  private _publishUpdatedListToStream(
    cardCollections: CardCollection[],
    requestType: CardRequestType,
    domainKey?: string
  ): void {
    if (cardCollections != null && requestType !== CardRequestType.SEARCH) {
      const cardCollectionsStatus = new DataStatus<CardCollection[]>(
        Status.done,
        new StatusMessage(HttpStatusCode.OK, 'OK'),
        cardCollections
      );
      if (domainKey) {
        this._setDomainFilteredCardCollections(domainKey, requestType, cardCollectionsStatus);
      } else {
        this._cardSubjects.get(requestType).next(cardCollectionsStatus);
      }
    } else if (requestType === CardRequestType.SEARCH && this._searchBloc.searchResultsValue != null) {
      // TODO: this tightly couples SearchBloc and CardBloc,
      //  maybe move Card Events to a shared third bloc
      this._searchBloc.publishUpdatedSearchResultsToStream(
        new DataStatus<Search>(Status.done, new StatusMessage(200, 'OK'), this._searchBloc.searchResultsValue.data),
        true
      );
    }
  }

  private _setDomainFilteredCardCollections(
    domainKey: string,
    requestType: CardRequestType,
    cardCollectionsStatus: DataStatus<CardCollection[]>
  ) {
    if (this._domainFilteredCardCollections$[domainKey] == null) {
      this._domainFilteredCardCollections$[domainKey] = {};
    }
    if (this._domainFilteredCardCollections$[domainKey][requestType] == null) {
      this._domainFilteredCardCollections$[domainKey][requestType] = new BehaviorSubject<DataStatus<CardCollection[]>>(
        cardCollectionsStatus
      );
    } else {
      this._domainFilteredCardCollections$[domainKey][requestType].next(cardCollectionsStatus);
    }

    // Needed for challenge actions separate from filters
    if (requestType === CardRequestType.DOMAIN_ACTION || requestType === CardRequestType.DOMAIN_AVAILABLE) {
      this._cardSubjects.get(requestType).next(cardCollectionsStatus);
    }
  }

  private _getMatchingCard(event: Partial<CardEvent>): CardItem | LocalResourcesProgram {
    return this._cardCollectionService.cardsById?.[
      isOfType<LocalResourcesProgram, CardItem>(event?.card, 'contentId') ? event?.card?.contentId : event?.card?.id
    ];
  }

  handleHabitEvents(cardEventType: CardEventType, cardId: string, habitId?: string, habitText?: string) {
    switch (cardEventType) {
      case CardEventType.HABIT_ADDED:
        this._cardApi.addHabit(cardId, habitText).then((habit) => {
          if (!habit) {
            console.warn('Error adding habit');
            return;
          }
          this._handleHabitChanges(cardEventType, cardId, habit);
        });
        break;
      case CardEventType.HABIT_EDITED:
        this._cardApi.updateHabit(cardId, habitId, habitText).then((habit) => {
          if (!habit) {
            console.warn('Error updating habit');
            return;
          }
          this._handleHabitChanges(cardEventType, cardId, habit);
        });
        break;
      case CardEventType.HABIT_DELETED:
        this._cardApi.removeHabit(cardId, habitId).then((response) => {
          if (!response) {
            console.warn('Error deleting habit');
            return;
          }
          this._handleHabitChanges(cardEventType, cardId, new Habit().deserialize({ id: habitId }));
        });
        break;
    }
  }

  private _handleHabitChanges(cardEventType: CardEventType, cardId: string, habit: Habit) {
    const card = this._getMatchingCard({ card: new CardItem().deserialize({ id: cardId }) });
    const userState: UserCardState = card.userState;
    if (cardEventType === CardEventType.HABIT_ADDED) {
      if (userState?.habits) {
        userState.habits.push(habit);
      } else {
        userState.habits = [habit];
      }
    } else if (cardEventType === CardEventType.HABIT_EDITED) {
      const originalHabit = userState.habits.find((originalHabit) => originalHabit.id === habit.id);
      originalHabit.value = habit.value;
    } else {
      userState.habits = userState.habits.filter((originalHabit) => originalHabit.id !== habit.id);
    }
    if (this.detailViewClicked.value?.detailViewClicked && this.detailViewClicked.value?.card) {
      assertIsOfType<CardItem, LocalResourcesProgram>(card, 'isRepeatable');
      this.detailViewClicked.next({ ...this.detailViewClicked.value, card: card });
    }
  }

  handleCardNotifications(cardId: string, notifications: NotificationInfo[]) {
    const card = this._getMatchingCard({ card: new CardItem().deserialize({ id: cardId }) });
    card.userState.notifications = notifications;
    if (this.detailViewClicked.value?.detailViewClicked && this.detailViewClicked.value?.card) {
      assertIsOfType<CardItem, LocalResourcesProgram>(card, 'isRepeatable');
      this.detailViewClicked.next({ ...this.detailViewClicked.value, card: card });
    }
  }

  private _handleInteractionAPIEvent(event: CardEvent, batch: boolean) {
    assertIsOfType<CardItem, LocalResourcesProgram>(event.card, 'isRepeatable');
    if (batch) {
      void this._addToBatchEvents(event);
    } else {
      void this._cardApi.createCardEvent(event.card, event);
    }
  }

  private async _handleTrackAPIEvent(event: CardEvent, card: CardItem): Promise<void> {
    if (card.isActionable) {
      const userState: UserCardState = card.userState;
      if (event.type === CardEventType.TRACK) {
        userState.currentState = userState.isCompleted ? CardItemState.ACT_COMPLETE : CardItemState.ACT_TO_DO;
        userState.isTracking = true;
        userState.trackingJustChanged = true;
      } else if (
        event.type === CardEventType.UNTRACK &&
        ![CardRequestType.TRACKING, CardRequestType.SAVED, CardRequestType.TOTD].includes(event.requestType)
      ) {
        userState.currentState = CardItemState.DISCOVER_DEFAULT;
        userState.isTracking = false;
        userState.trackingJustChanged = false;
      } else if (
        event.type === CardEventType.UNTRACK &&
        [CardRequestType.TRACKING, CardRequestType.SAVED, CardRequestType.TOTD].includes(event.requestType)
      ) {
        userState.isTracking = false;
        userState.trackingJustChanged = false;
        userState.currentState = CardItemState.ACT_REMOVED;
        this._updateCardsToRemoveStream(card, true);
      }
    }

    if (card) {
      if (event.type === CardEventType.TRACK) {
        // API Call: TRACK
        await this._cardApi.createCard(card);
      } else if (event.type === CardEventType.UNTRACK) {
        // API Call: DELETE (remove from act)
        await this._cardApi.deleteCard(card);
      }
    } else {
      console.log('No cards to track');
    }
  }

  private _updateCardsToRemoveStream(card: CardItem, add: boolean): void {
    if (add) {
      this._cardsToRemove.push(card);
    } else {
      remove(this._cardsToRemove, card);
    }
    this._cardCollectionService.addCard(card);
  }

  private _handleMoreAPIEvent(event: CardEvent, card: CardItem | LocalResourcesProgram, batch: boolean): void {
    if (card.userState.preference === UserCardPreference.MORE) {
      card.userState.preference = UserCardPreference.NEUTRAL;
      event.type = CardEventType.NEUTRAL;
      card.userState.likeCount -= 1;
    } else {
      card.userState.preference = UserCardPreference.MORE;
      card.userState.likeCount += 1;
    }
    // API Call: MORE
    if (batch) {
      void this._addToBatchEvents(event);
    } else {
      void this._cardApi.createCardEvent(card, event);
    }
  }

  private _handleLessAPIEvent(event: CardEvent, card: CardItem | LocalResourcesProgram, batch: boolean): void {
    if (card.userState.preference === UserCardPreference.LESS) {
      card.userState.preference = UserCardPreference.NEUTRAL;
      event.type = CardEventType.NEUTRAL;
      card.userState.likeCount += 1;
    } else {
      card.userState.preference = UserCardPreference.LESS;
      card.userState.likeCount -= 1;
    }
    // API Call: LESS
    if (batch) {
      void this._addToBatchEvents(event);
    } else {
      void this._cardApi.createCardEvent(card, event);
    }
  }

  private async _handleCompleteAPIEvent(event: CardEvent, card: CardItem, batch: boolean): Promise<void> {
    // API Call: COMPLETE
    const clonedEvent = event.clone();
    // This line is needed as the event might not have the most up-to-date requestType for the card
    // ex. event's requestType is AVAILABLE but the card in card collection service has been tracked
    if (card.userState.isTracking) {
      clonedEvent.requestType = CardRequestType.TRACKING;
    }
    if (batch) {
      void this._addToBatchEvents(event);
    } else {
      const response = await this._cardApi.createCardEvent(card, clonedEvent);
      if (response != null && response['id'] != null && response['id'] !== '') {
        card.userState.shownAnimation = false;
        card.userState.currentState = CardItemState.ACT_ANIMATION;
        card.userState.completedId = response['id'] as string;
        card.userState.completedCount += 1;
      } else {
        card.userState.currentState = CardItemState.ERROR_COMPLETE;
      }
    }
  }

  private async _handleUncompleteAPIEvent(event: CardEvent, card: CardItem): Promise<void> {
    // API Call: UNCOMPLETE
    event.id = card.userState.completedId;
    const clonedEvent = event.clone();
    // Overriding requestType because API currently only allows TRACKING cards to be uncompleted
    if (clonedEvent.requestType !== CardRequestType.QUEST) {
      clonedEvent.requestType = CardRequestType.TRACKING;
    }
    await this._cardApi.deleteCardEvent(card, clonedEvent);
    card.userState.currentState = CardItemState.ACT_TO_DO;
    card.userState.isTracking = true;
    card.userState.shownAnimation = true;
    card.userState.completedId = null;
    card.userState.completedCount -= 1;
  }

  private _handleAnimationCompleteUIEvent(card: CardItem): void {
    card.userState.shownAnimation = true;
    if (card.userState.isCompleted === true) {
      card.userState.currentState = CardItemState.ACT_COMPLETE;
    } else {
      card.userState.currentState = CardItemState.ACT_TO_DO;
    }
  }

  private _handleResetErrorUIEvent(event: CardEvent, card: CardItem): void {
    if (event.requestType === CardRequestType.TRACKING) {
      card.userState.currentState = CardItemState.ACT_TO_DO;
    } else if (event.requestType === CardRequestType.AVAILABLE) {
      card.userState.currentState = card.userState.isTracking
        ? CardItemState.DISCOVER_ADDED_TO_ACT
        : CardItemState.DISCOVER_DEFAULT;
    }
  }

  private _handleUpdateChallengeStatus() {
    this.hasCompletedCards$.pipe(take(1)).subscribe((hasCompletedCards) => {
      this._dailyChallengeStatusBloc.refreshDailyChallengeStatus();
    });
  }

  private _filterCollection(
    collection: CardCollection,
    isCompleted: boolean,
    newName: string,
    showAllCards = false
  ): CardCollection {
    const notCompletedStates = [CardItemState.ACT_TO_DO, CardItemState.ACT_ANIMATION];
    if (collection?.cardItems) {
      let cardItems = Object.values(collection.cardItems || {}).sort(
        (cardA, cardB) =>
          +notCompletedStates.includes(cardB.userState.currentState) -
          +notCompletedStates.includes(cardA.userState.currentState)
      );
      cardItems = cardItems.filter((item: CardItem) => !excludeFromActions(item));
      if (!showAllCards) {
        cardItems = Object.values(collection.cardItems || {}).filter((item: CardItem) =>
          includeInCollection(item, isCompleted)
        );
      }
      return new CardCollection(
        newName,
        null,
        cardItems.reduce((acc: JsonObject, cardItem) => {
          acc[cardItem.id] = cardItem;
          return acc;
        }, {})
      );
    }
    return null;
  }

  private _updateCardCollection(
    requestType: CardRequestType,
    updateStream: boolean,
    card: CardItem | LocalResourcesProgram,
    domainKey?: string
  ): void;
  private _updateCardCollection(
    requestType: CardRequestType,
    updateStream: boolean,
    cardCollection: CardCollection[],
    domainKey?: string
  ): void;
  private _updateCardCollection(
    requestType: CardRequestType,
    updateStream = true,
    cardOrCardCollection: CardItem | LocalResourcesProgram | CardCollection[],
    domainKey?: string
  ) {
    if (cardOrCardCollection) {
      let cardCollection: CardCollection[] = cardOrCardCollection as CardCollection[];
      if (cardOrCardCollection instanceof CardItem || cardOrCardCollection instanceof LocalResourcesProgram) {
        cardCollection = [
          new CardCollection().deserialize({ name: '', description: '', cardItems: [cardOrCardCollection] }),
        ];
        this._cardCollectionService.addCard(cardOrCardCollection);
      } else {
        cardOrCardCollection.forEach((cardCollection) => {
          this._cardCollectionService.addCards(Object.values(cardCollection.cardItems || {}));
        });
      }
      if (updateStream) {
        this._publishUpdatedListToStream(cardCollection, requestType, domainKey);
      }
    }
  }

  private _filterSavedCards(collection: CardCollection): CardCollection {
    if (collection && collection.cardItems) {
      const cardIds = Object.values(collection.cardItems || {})
        .filter((item: CardItem) => item.userState.saveState.isSaved)
        .map((cardItem) => cardItem.id);
      return new CardCollection().deserialize({
        name: 'Saved Cards',
        cardItems: Object.values(this._cardCollectionService.cardsById || {})
          .filter((card) => cardIds?.includes(card?.id))
          .reduce((acc: JsonObject, cardItem) => {
            acc[cardItem.id] = cardItem;
            return acc;
          }, {}),
      });
    }
    return null;
  }
}

function chooseOurIdFromDifferentlyStructuredObjects(card: CardItem | LocalResourcesProgram): string {
  return isOfType<LocalResourcesProgram, CardItem>(card, 'contentId') ? card.contentId : card.id;
}

function includeInCollection(cardItem: CardItem, onlyCompletedCards: boolean): boolean {
  const userCompleted: boolean = cardItem.userState.isCompleted;
  const currentCardState: CardItemState = cardItem.userState.currentState;
  const excludeFromActions: boolean = cardItem.excludeFrom?.includes('Act');

  // Get card states
  const cardInCompletedState = [CardItemState.ACT_COMPLETE, CardItemState.ACT_REMOVED].includes(currentCardState);
  const cardInToDoState = [CardItemState.ACT_TO_DO, CardItemState.ERROR_COMPLETE, CardItemState.ACT_REMOVED].includes(
    currentCardState
  );
  const cardTransitioningFromToDoState = [CardItemState.ACT_ANIMATION].includes(currentCardState);

  // Check if card should be shown for getCompletedCards state
  const showInCompleted = onlyCompletedCards && userCompleted && cardInCompletedState && !excludeFromActions;
  const showInToDo =
    !excludeFromActions &&
    !onlyCompletedCards &&
    ((!userCompleted && cardInToDoState) || (userCompleted && cardTransitioningFromToDoState));

  return showInCompleted || showInToDo;
}

function excludeFromActions(cardItem: CardItem) {
  return cardItem.excludeFrom?.includes('Act');
}
