import { compareAsc } from 'date-fns/compareAsc';
import { format } from 'date-fns/format';
import { isSameDay } from 'date-fns/isSameDay';
import { enUS } from 'date-fns/locale/en-US';
import { StringUtil } from './string.util';

export enum PartOfDay {
  morning,
  afternoon,
  evening,
}

export enum ClockSystem {
  hr12,
  hr24,
}

export type DaysWeek = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday';
export type DaysWeekAbbreviation = 'Sun' | 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat';
export type DaysWeekShort = 'SU' | 'MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA';
export type WeekDayData = {
  [day in DaysWeek]: {
    isWeekend: boolean;
    isWeekday: boolean;
    abbreviation: DaysWeekAbbreviation;
    shortName: DaysWeekShort;
  };
};

export class DateTimeUtil {
  static dateFormatForLocal = "yyyy-MM-dd'T'HH:mm:ss.SSSXXX";
  static dateFormatForDateNoTime = 'yyyy-MM-dd';

  static weekDaysData: WeekDayData = {
    sunday: { isWeekend: true, isWeekday: false, abbreviation: 'Sun', shortName: 'SU' },
    monday: { isWeekend: false, isWeekday: true, abbreviation: 'Mon', shortName: 'MO' },
    tuesday: { isWeekend: false, isWeekday: true, abbreviation: 'Tue', shortName: 'TU' },
    wednesday: { isWeekend: false, isWeekday: true, abbreviation: 'Wed', shortName: 'WE' },
    thursday: { isWeekend: false, isWeekday: true, abbreviation: 'Thu', shortName: 'TH' },
    friday: { isWeekend: false, isWeekday: true, abbreviation: 'Fri', shortName: 'FR' },
    saturday: { isWeekend: true, isWeekday: false, abbreviation: 'Sat', shortName: 'SA' },
  };

  static allDays = Object.keys(this.weekDaysData) as DaysWeek[];
  static weekdays = Object.values(this.weekDaysData)
    .filter((day) => day.isWeekday)
    .map((days) => days.abbreviation);
  static weekends = Object.values(this.weekDaysData)
    .filter((day) => day.isWeekend)
    .map((days) => days.abbreviation);
  static everyDay = Object.values(this.weekDaysData).map((days) => days.abbreviation);

  static weekdayName(date: Date, toLowerCase = false) {
    const day = this.allDays[date?.getDay?.() ?? 0];
    return toLowerCase ? day : StringUtil.capitalizeFirstLetter(day);
  }

  static monthName(date: Date) {
    if (date == null) {
      return null;
    }
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    return months[date.getMonth()];
  }

  static monthArray() {
    return [
      {
        name: 'January',
        number: 1,
      },
      {
        name: 'February',
        number: 2,
      },
      {
        name: 'March',
        number: 3,
      },
      {
        name: 'April',
        number: 4,
      },
      {
        name: 'May',
        number: 5,
      },
      {
        name: 'June',
        number: 6,
      },
      {
        name: 'July',
        number: 7,
      },
      {
        name: 'August',
        number: 8,
      },
      {
        name: 'September',
        number: 9,
      },
      {
        name: 'October',
        number: 10,
      },
      {
        name: 'November',
        number: 11,
      },
      {
        name: 'December',
        number: 12,
      },
    ];
  }

  static get daysInWeek(): 7 {
    return 7;
  }

  static hourArray(clockSystem: ClockSystem) {
    const hourArray: string[] = [];
    switch (clockSystem) {
      case ClockSystem.hr24:
        for (let i = 0; i <= 23; i++) {
          hourArray.push(leftPad(i));
        }
        break;
      case ClockSystem.hr12:
        for (let i = 1; i <= 12; i++) {
          hourArray.push(i.toString());
        }
        break;
    }
    return hourArray;
  }

  static reminderMinutes(): string[] {
    const minutesArray: string[] = [];
    for (let i = 0; i <= 45; i = i + 15) {
      minutesArray.push(leftPad(i));
    }
    return minutesArray;
  }

  static AMPM(): string[] {
    return ['AM', 'PM'];
  }

  static formatInLocal(date: Date = new Date()): string {
    return format(date, this.dateFormatForLocal, { locale: enUS });
  }

  static getLocalDateNoTZConversion(date: string): Date {
    if (!date) {
      return null;
    }
    // ensure date is a simple date
    const dateOnly = date.split('T')?.[0];
    return new Date(`${dateOnly}T00:00:00`);
  }

  static getTimeZone(date: Date = new Date()): string {
    return format(date, 'XX');
  }

  /// Convert date to format "2018-10-15" by default or user provided date-fns format string
  static formatDate(date = new Date(), formatString = this.dateFormatForDateNoTime, locale = enUS) {
    if (date && formatString) {
      if (locale) {
        return format(date, formatString, { locale });
      }
      return format(date, formatString, { locale: enUS });
    }
    return format(date || new Date(), formatString, { locale: locale ?? enUS });
  }

  /// get formatted start and end date back
  static formatDates(startDate: Date, endDate: Date) {
    const useStartDate = startDate && compareAsc(startDate, endDate) === -1;
    const startDateFormatted = useStartDate ? format(startDate, this.dateFormatForLocal) : null;
    const endDateFormatted = format(endDate || new Date(), this.dateFormatForLocal);
    return { start: startDateFormatted, end: endDateFormatted };
  }

  static formatSecondsAsTime(secs: number) {
    //must convert to string in order to add 0 to front...otherwise it won't convert to string without error
    const hr = Math.floor(secs / 3600);
    const min = Math.floor((secs - hr * 3600) / 60);
    let minStr = Math.floor((secs - hr * 3600) / 60).toString();
    const sec = Math.floor(secs - hr * 3600 - min * 60);
    let secStr = Math.floor(secs - hr * 3600 - min * 60).toString();

    if (min < 10) {
      minStr = '0' + min;
    }
    if (sec < 10) {
      secStr = '0' + sec;
    }

    if (!isNaN(min) && !isNaN(sec)) {
      return minStr + ':' + secStr || min + ':' + sec;
    } else {
      return false;
    }
  }

  static toISOLocal(date: Date) {
    let off = date.getTimezoneOffset();
    const sign = off < 0 ? '+' : '-';
    off = Math.abs(off);

    return (
      date.getFullYear() +
      '-' +
      leftPad(date.getMonth() + 1) +
      '-' +
      leftPad(date.getDate()) +
      'T' +
      leftPad(date.getHours()) +
      ':' +
      leftPad(date.getMinutes()) +
      ':' +
      leftPad(date.getSeconds()) +
      '.' +
      leftPad(date.getMilliseconds(), 3) +
      sign +
      // tslint:disable-next-line: no-bitwise
      leftPad((off / 60) | 0) +
      ':' +
      leftPad(off % 60)
    );
  }

  static isMorning(dateTime: Date) {
    return dateTime.getHours() >= 4 && dateTime.getHours() <= 11;
  }

  static isAfternoon(dateTime: Date) {
    return dateTime.getHours() >= 12 && dateTime.getHours() <= 17;
  }

  static isEvening(dateTime: Date) {
    return dateTime.getHours() >= 18 || dateTime.getHours() <= 3;
  }

  static currentPartOfDay(dateTime: Date = new Date()) {
    if (this.isMorning(dateTime)) {
      return PartOfDay.morning;
    } else if (this.isAfternoon(dateTime)) {
      return PartOfDay.afternoon;
    } else {
      return PartOfDay.evening;
    }
  }

  static getTimeOfDay(dateTime: Date = new Date()) {
    if (this.isMorning(dateTime)) {
      return PartOfDay[PartOfDay.morning];
    } else if (this.isAfternoon(dateTime)) {
      return PartOfDay[PartOfDay.afternoon];
    } else {
      return PartOfDay[PartOfDay.evening];
    }
  }

  static getDayName(date: Date = new Date()) {
    if (date == null) {
      return null;
    }
    //      day: DateFormat('EEEE', PflLocalizations.getLocaleAndCountryCode(context)).format(date),
    return format(date, 'EEEE', { locale: enUS });
  }

  static earliestReflectionToday(): Date {
    const date = new Date();
    date.setHours(4);
    date.setMinutes(0);
    date.setMilliseconds(0);
    return date;
  }

  /// Gets the Monday and Sunday from the week [dateTime] falls in.
  static pastWeekRange(date: Date = new Date(), resetTimeParams: false) {
    const result: Date[] = [];
    for (let i = 0; i < this.daysInWeek; i++) {
      date.setDate(date.getDay() - i);
      result.push(date);
      if (resetTimeParams) {
        const current = result[i];
        result[i] = new Date(current.getFullYear(), current.getMonth(), current.getDay());
      }
    }
    return result.reverse();
  }

  static convertUTCDateToLocalDate(date: Date) {
    const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    const offset = date.getTimezoneOffset() / 60;
    const hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }

  static isSameDay(day1: Date, day2: Date) {
    return isSameDay(day1, day2);
  }

  static isFromTimeOfDay(dateTime: Date, exactTime: Date) {
    if (exactTime == null || dateTime == null) {
      return false;
    }
    return (
      isSameDay(dateTime, exactTime) &&
      DateTimeUtil.currentPartOfDay(exactTime) === DateTimeUtil.currentPartOfDay(dateTime)
    );
  }

  static stripTimeFromDate(date: Date = new Date()) {
    return date != null ? new Date(date.getFullYear(), date.getMonth(), date.getDate()) : this.now();
  }

  static now(daysOffset = 0): Date {
    const date = new Date(this.stripTimeFromDate(new Date()));

    date.setDate(date.getDate() + daysOffset);
    return date;
  }

  static compareDate(date1: Date, date2: Date): number {
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    return compareAsc(d1, d2);
  }

  static formatIn24hr(timeString: string) {
    const date = new Date().toLocaleTimeString();
    const is24HourFormat = !date.match(/am|pm/i);
    return !is24HourFormat ? DateTimeUtil.convert12HourTo24HrFromString(timeString) : timeString;
  }

  static convert12HourTo24HrFromString(timeString: string): string {
    let hour = timeString.substring(0, timeString.indexOf(':'));
    let minutes = timeString.substring(timeString.indexOf(':') + 1, timeString.indexOf(':') + 3);
    const AMPM = timeString.substring(timeString.length - 2);
    if (Number(hour) !== 12 && AMPM === 'PM') {
      hour = (Number(hour) + 12).toString();
    } else if (AMPM === 'AM' && Number(hour) === 12) {
      hour = (Number(hour) - 12).toString();
    }
    hour = leftPad(hour, 2);
    minutes = leftPad(minutes, 2);
    return `${hour}:${minutes}`;
  }

  static getTimeFromCron(cron: string): string {
    if (!cron) {
      return null;
    }
    const date: Date = DateTimeUtil.cronToDateTime(cron);
    const now = new Date().toLocaleTimeString();
    const is24HourFormat = !now.match(/am|pm/i);
    return is24HourFormat ? format(date, 'H:mm') : format(date, 'h:mm a');
  }

  static cronToDateTime(cron: string): Date {
    if (!cron) {
      return null;
    }
    const now = new Date();
    const dateString = cron.split(' ');
    const minute = Number(dateString[0]);
    const hour = Number(dateString[1]);
    now.setMinutes(minute);
    now.setHours(hour);
    return now;
  }

  static timeToCron(time: string): string {
    const is24HourFormat = !time.match(/am|pm/i);
    const timeIn24HourFormat = is24HourFormat ? time : DateTimeUtil.formatIn24hr(time);
    const hour = Number(timeIn24HourFormat.substring(0, timeIn24HourFormat.indexOf(':')));
    const minutes = time.substring(time.indexOf(':') + 1, time.indexOf(':') + 3);
    return `${minutes} ${hour} * * *`;
  }

  static getHourAndMinutesFromTime(time: string) {
    const dateTime = new Date(`${format(new Date(), 'yyyy-MM-dd')} ${this.convert12HourTo24HrFromString(time)}`);
    const hour = +format(dateTime, 'H');
    const minutes = +format(dateTime, 'mm');
    return { hour, minutes };
  }
}

function leftPad(n: string | number, pad = 2, char = '0') {
  const padBase = Array.from({ length: pad }, () => char).join('');
  return (padBase + n).slice(-1 * pad);
}
