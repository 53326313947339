<ng-container *transloco="let t">
  <button (click)="toggleSave($event)" [attr.aria-label]="isSaved ? t('Un-save') : t('Save')" class="save-button">
    <kp-simple-icon-animation
      [ngClass]="{ 'is-saved': isSaved }"
      [options]="options"
      [iconShouldFill]="isSaved"
      [iconClicked]="saveClicked"
      [color]="color"></kp-simple-icon-animation>
    <ng-content></ng-content>
  </button>
</ng-container>
