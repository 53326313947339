import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Document } from '@contentful/rich-text-types';
import { TagBloc } from '@kbloc';
import { CardEventType } from '@ktypes/enums';
import { DataStatus, SocialChallenge, SocialChallengeState, Status } from '@ktypes/models';
import { compareDesc } from 'date-fns';
import { BehaviorSubject, Observable, distinctUntilChanged, scan, shareReplay, withLatestFrom } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { SocialChallengeApi } from './social-challenge.api';

export const CELEBRATION_DISPLAY_TIME = 3000;

@Injectable({
  providedIn: 'root',
})
export class SocialChallengeBloc {
  constructor(
    private _router: Router,
    private _socialChallengeApi: SocialChallengeApi,
    private _tagBloc: TagBloc
  ) {}

  // NOTE: this is the root observable that all other Challenge Data should derive from
  private _challengesStatus$ = new BehaviorSubject<DataStatus<SocialChallenge[]>>(
    new DataStatus<SocialChallenge[]>(Status.local, [])
  );

  private _celebrationModalChallenge$ = new BehaviorSubject<SocialChallenge>(null);

  private _currentChallengeStatus$ = new BehaviorSubject<DataStatus<SocialChallenge>>(null);

  // full collection of challenges by challengeId
  private _challengesById$: Observable<{ [challengeId: string]: SocialChallenge }> = this._challengesStatus$.pipe(
    filter((challengesStatus) => challengesStatus?.data != null),
    scan((existingChallenges, challengesStatus) => {
      const updatedChallenges = challengesStatus.data?.reduce((challenges, challenge) => {
        return { ...challenges, [challenge.id]: challenge };
      }, {});
      return { ...(existingChallenges || {}), ...(updatedChallenges || {}) };
    }, {}),
    shareReplay(1)
  );

  private _challenges$: Observable<SocialChallenge[]> = this._challengesById$.pipe(
    filter((challenges) => challenges != null),
    map((challenges) => Object.values(challenges || {}))
  );

  // track the last challenge requested by ID to prevent race conditions
  private _latestChallengeRequestedId: string;

  // NOTE: Based logic on the API spec, API should be returning in sorted order
  //       but sorting here since it wasn't at one time
  // SPEC: Challenges returned should be ordered within each of the categories of active, upcoming
  //       and ended in reverse chronological order by the date the user joined the challenge.
  mostRecentJoinedChallenge$ = this._challenges$.pipe(
    map((challenges) =>
      challenges
        .sort((c1, c2) => {
          const stateSortOrder = [
            SocialChallengeState.active,
            SocialChallengeState.upcoming,
            SocialChallengeState.ended,
          ];
          if (c1.status.state === c2.status.state) {
            if (c1.individual.status.joinDate != null && c2.individual.status.joinDate != null) {
              return compareDesc(c1.individual.status.joinDate, c2.individual.status.joinDate);
            }
            return compareDesc(c1.startDate, c2.startDate);
          }
          return stateSortOrder.indexOf(c1.status.state) - stateSortOrder.indexOf(c2.status.state);
        })
        .find(
          (challenge) =>
            challenge.individual.status.enrolled &&
            [SocialChallengeState.active, SocialChallengeState.upcoming].includes(challenge.status.state)
        )
    )
  );

  hasAJoinedNonEndedChallenge$ = this._challenges$.pipe(
    map((challenges) => {
      return challenges.some(
        (challenge) =>
          challenge.individual.status.enrolled &&
          [SocialChallengeState.active, SocialChallengeState.upcoming].includes(challenge.status.state)
      );
    })
  );

  get celebrationModalChallenge$() {
    return this._celebrationModalChallenge$.asObservable();
  }
  showCelebrationModal$: Observable<boolean> = this._celebrationModalChallenge$.pipe(
    withLatestFrom(this._tagBloc.socialChallengeEnabled$),
    map(([challenge, enabled]) => enabled && challenge != null)
  );

  get challengesStatus$(): Observable<DataStatus<SocialChallenge[]>> {
    return this._challengesStatus$.pipe(
      filter((challengesStatus) => challengesStatus != null),
      distinctUntilChanged()
    );
  }

  get challenges$(): Observable<SocialChallenge[]> {
    return this._challenges$.pipe(distinctUntilChanged());
  }

  get challengesById$(): Observable<{ [challengeId: string]: SocialChallenge }> {
    return this._challengesById$.pipe(distinctUntilChanged());
  }

  get currentChallenge$(): Observable<SocialChallenge> {
    return this._currentChallengeStatus$.pipe(
      map((challengeStatus) => challengeStatus?.data || null),
      distinctUntilChanged()
    );
  }

  clearCurrentChallenge(): void {
    this._currentChallengeStatus$.next(null);
  }

  // NOTE: This method pulls from existing challenges, it does not make an API call
  getChallengesByState$(challengeState: SocialChallengeState): Observable<SocialChallenge[]> {
    return this._challenges$.pipe(
      filter((challenges) => challenges != null),
      // NOTE: This trusts the API that the current date is before, during, or after the
      //       challenge dates respectively, based on challenge.status.state
      map((challenges) => challenges?.filter?.((challenge) => challenge.status?.state === challengeState) || []),
      distinctUntilChanged()
    );
  }

  toggleEnrolledStateLocally(challengeId: string): void {
    this._challengesById$.pipe(take(1)).subscribe((challenges) => {
      const challenge = challenges?.[challengeId];
      if (challenge?.individual?.status != null) {
        challenge.individual.status.enrolled = !challenge.individual.status.enrolled;
        if (typeof challenge.group?.status?.participating === 'number') {
          challenge.group.status.participating += challenge.individual.status.enrolled ? 1 : -1;
        }
        // Updating state locally (optimistic)
        this._challengesStatus$.next(
          new DataStatus(Status.done, [
            ...Object.values(challenges).filter((challenge) => challenge.id !== challenge?.id),
            challenge,
          ])
        );
      }
    });
  }

  // Celebration Modal method(s)
  updateChallengeStateBasedOnActionChange(challenge: SocialChallenge, cardEventType: CardEventType) {
    switch (cardEventType) {
      case CardEventType.COMPLETE:
      case CardEventType.QUEST_CARD_COMPLETE:
        if (!challenge.individual.status.completedToday) {
          this._celebrationModalChallenge$.next(challenge);

          // set timer to dismiss modal after animation finishes
          setTimeout(() => {
            this._celebrationModalChallenge$.next(null);
          }, CELEBRATION_DISPLAY_TIME);
        }
        this._updateChallenge(challenge, 1);
        break;
      case CardEventType.UNCOMPLETE:
        if (challenge.individual.status.completedToday) {
          this._updateChallenge(challenge, -1);
        }
        break;
    }
  }

  closeCelebrationModal() {
    this._celebrationModalChallenge$.next(null);
  }

  // API interfaces
  getChallenges(triggerStartedStatus = true): void {
    if (triggerStartedStatus) {
      this._challengesStatus$.next(new DataStatus<SocialChallenge[]>(Status.starting));
    }
    this._socialChallengeApi.getChallenges().then((challengesStatus) => {
      this._challengesStatus$.next(challengesStatus);
    });
  }

  getChallengeById(id: string, forceRefresh = false, backgroundRefresh = false): void {
    if (id == null) {
      console.warn('getChallengeById: cannot get specific challenge without id');
      return;
    }
    this._latestChallengeRequestedId = id;
    if (!backgroundRefresh) {
      this._currentChallengeStatus$.next(new DataStatus<SocialChallenge>(Status.starting));
    }
    // NOTE: Current getChallengeById will pull locally if challenge exists since they change infrequently, unless
    //       forceRefresh was passed as true.
    this._challengesById$.pipe(take(1)).subscribe((challenges) => {
      if (!forceRefresh && challenges?.[id] != null && id === this._latestChallengeRequestedId) {
        this._currentChallengeStatus$.next(new DataStatus<SocialChallenge>(Status.done, challenges[id]));
        return;
      }
      this._socialChallengeApi.getChallenge(id).then((challengeStatus) => {
        if (id === this._latestChallengeRequestedId) {
          // Add to currentChallengeStatus if most recent request regardless of success
          this._currentChallengeStatus$.next(challengeStatus);
        }

        // If successful, also add/update _challengesStatus$
        if (challengeStatus?.status === Status.done) {
          const currentChallengesStatus = this._challengesStatus$.getValue();
          const updatedChallenges =
            challengeStatus.data != null
              ? [
                  ...currentChallengesStatus.data.filter((challenge) => challenge.id !== challengeStatus?.data?.id),
                  challengeStatus.data,
                ]
              : currentChallengesStatus.data;
          const updatedStatus = [Status.local, Status.done].includes(currentChallengesStatus.status)
            ? Status.done
            : currentChallengesStatus.status;
          this._challengesStatus$.next(new DataStatus(updatedStatus, updatedChallenges));
        }
      });
    });
  }

  private _updateChallenge(challenge: SocialChallenge, modifier: -1 | 1): void {
    if (challenge.individual.status.progress.countToday > (modifier === 1 ? 0 : 1)) {
      // still update the countToday, even if doing nothing else
      challenge.individual.status.progress.countToday += modifier;
      // if the countToday already has an action, no UI updates needed for an action complete or uncomplete
      // may eventually want this to go ahead and call for an updated challenge if we switch to using the API
      return;
    }
    const goal = challenge.individual.goal;
    const originalCount = challenge.individual.status.progress.count;
    const originalGoalRemaining = goal - originalCount;
    const updatedCount = challenge.individual.status.progress.count + modifier;
    const updatedGoalRemaining = goal - updatedCount;

    // manually update challenge
    challenge.group.status.progress.count += modifier;
    challenge.individual.status.completedToday = modifier === 1;
    challenge.individual.status.progress.count = updatedCount;
    challenge.individual.status.progress.countToday += modifier;
    if (updatedCount <= goal && updatedGoalRemaining <= goal) {
      // yes, the following is 🤮 🤢 😵
      challenge.individual.motivation = JSON.parse(
        JSON.stringify(challenge.individual.motivation).replace(`${originalGoalRemaining}`, `${updatedGoalRemaining}`)
      ) as Document;
    }

    // Update challenge on next route change
    // NOTE: This can currently take upwards of 2 seconds; (subjectively) feels janky (as of 7/24/23 MB)
    //       to try to do it on the fly or to only update via API and not via the above optimistic updates
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        take(1)
      )
      .subscribe(() => {
        this.getChallengeById(challenge.id, true, true);
      });
  }
}
