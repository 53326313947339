<ng-container *transloco="let t">
  @if (inviteModalOpened) {
    <kui-modal [isOpen]="inviteModalOpened" (isOpenChange)="inviteModalOpened = false" type="info">
      <kui-modal-invite
        [invite]="sharingBloc.invites$ | async | inviteFilter: inviteType"
        [challengeId]="challenge.id"
        [challengeTitle]="challenge.title"
        [challengeInviteMessage]="challenge.inviteMessage"
        (closeClicked)="inviteModalOpened = false"></kui-modal-invite>
    </kui-modal>
  }

  <button class="menu-toggle" [tp]="challengeMenu" [tpHideOnEscape]="true" tpVariation="challengeMenu" #menu="tippy">
    <svg-icon class="toggle-icon" src="/assets/icons/ellipsis.svg"></svg-icon>
  </button>

  <ng-template #challengeMenu>
    <nav class="menu">
      @if (
        challenge?.individual?.status?.enrolled &&
          challenge?.inviteMessage &&
          (challenge?.status?.state === socialChallengeState.upcoming ||
            challenge?.status?.state === socialChallengeState.active) &&
          sharingBloc.invites$
          | async
          | inviteFilter: inviteType
      ) {
        <button class="menu-option" (click)="invite() && menu.hide()" (keydown.enter)="invite() && menu.hide()">
          <svg-icon class="menu-icon" src="/assets/icons/user-invite.svg"></svg-icon>
          {{ t('f_sc.Invite') }}
        </button>
      }
      @if (!challenge?.individual.status.enrolled) {
        <button
          class="menu-option"
          (click)="toggleEnrolledStatus(contentEventType.challenge_join) && menu.hide()"
          (keydown.enter)="toggleEnrolledStatus(contentEventType.challenge_join) && menu.hide()">
          <svg-icon class="menu-icon" src="/assets/icons/plus-circle.svg"></svg-icon>
          {{ t('f_sc.Join Challenge') }}
        </button>
      }
      @if (challenge?.individual.status.enrolled) {
        <button
          class="menu-option"
          (click)="toggleEnrolledStatus(contentEventType.challenge_leave) && menu.hide()"
          (keydown.enter)="toggleEnrolledStatus(contentEventType.challenge_leave) && menu.hide()">
          <svg-icon class="menu-icon" src="/assets/icons/minus-circle.svg"></svg-icon>
          {{ t('f_sc.Leave Challenge') }}
        </button>
      }
    </nav>
  </ng-template>
</ng-container>
