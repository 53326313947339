<ng-container *transloco="let t">
  <a
    routerLink="/purposeful-days"
    [attr.aria-label]="
      t(
        'Click link for more information about Purposeful Days. Link shows three diamonds indicating Purposeful Day status'
      ) +
      ' ' +
      (complete
        ? t('Completed! All filled')
        : t('1 Showed Up?') +
          ' ' +
          (showedUp ? t('done') : t('not yet')) +
          ' ' +
          t('2 Checked In?') +
          ' ' +
          (checkedIn ? t('done') : t('not yet')) +
          ' ' +
          t('3 Action Completed?') +
          ' ' +
          (actionCompleted ? t('done') : t('not yet'))) +
      (streakLength > 0 ? ' ' + t('Purposeful Day Streak') + ' ' + streakLength : '')
    "
    (click)="purposefulDaysClicked.emit()">
    <div class="purposeful-days-indicator" [ngClass]="{ 'purposeful-day-fulfilled': complete }">
      <div class="purposeful-days-diamond" [ngClass]="{ 'purposeful-day-item-fulfilled': showedUp }">
        @if (showedUp) {
          <div class="purposeful-days-inner-diamond"></div>
        }
      </div>
      <div class="bullet-separator"></div>
      <div class="purposeful-days-diamond" [ngClass]="{ 'purposeful-day-item-fulfilled': checkedIn }">
        @if (checkedIn) {
          <div class="purposeful-days-inner-diamond"></div>
        }
      </div>
      <div class="bullet-separator"></div>
      <div class="purposeful-days-diamond" [ngClass]="{ 'purposeful-day-item-fulfilled': actionCompleted }">
        @if (actionCompleted) {
          <div class="purposeful-days-inner-diamond"></div>
        }
      </div>
      @if (streakLength > 0) {
        <div class="bullet-separator"></div>
      }
      @if (streakLength > 0) {
        <div class="purposeful-days-streak-flame">
          <svg-icon src="/assets/icons/streak-flame.svg"></svg-icon>
          <span class="streak-number">{{ streakLength }}</span>
        </div>
      }
    </div>
  </a>

  @if (showCelebrationModal) {
    <kui-modal
      [isOpen]="showCelebrationModal"
      (isOpenChange)="isCelebrationModalOpen()"
      (click)="showCelebrationModal = false"
      [canDismiss]="false"
      type="info">
      <kp-modal-purposeful-days (closeClicked)="showCelebrationModal = false"></kp-modal-purposeful-days>
    </kui-modal>
  }
</ng-container>
