<ng-container *transloco="let t">
  <div class="program-name">
    <h4>{{ office.name }}</h4>
    @if (office.isAdministrative) {
      <span>{{ t('Admin-Only') }}</span>
    }
  </div>
  <div class="location-info">
    <div class="contact-info">
      @if (office.city && office.state && office.distance) {
        <div class="location-item">
          <svg-icon src="/assets/icons/location.svg" class="hidden-print"></svg-icon>
          <div class="location-address hidden-screen">
            <p>
              <strong>{{ t('Address') }}</strong>
            </p>
            <ng-container *ngTemplateOutlet="location; context: { office }"></ng-container>
          </div>
          <div class="location-address hidden-print">
            <a [href]="googleMapsDirectionsLink" target="_blank" class="underline-link">
              <ng-container *ngTemplateOutlet="location; context: { office }"></ng-container>
            </a>
            @if (!office.isAdministrative) {
              <p class="distance">{{ t('distance miles away', { distance: office.distance }) }}</p>
            }
          </div>
        </div>
      }
      @if (websiteUrl) {
        <div class="location-item">
          <svg-icon src="/assets/icons/expand.svg" class="hidden-print"></svg-icon>
          <a [href]="websiteUrl" target="_blank" class="underline-link link-website">
            <span class="hidden-print">{{ t('Visit website') }}</span>
            <strong class="hidden-screen">{{ t('Website') }} -</strong>
          </a>
        </div>
      }
      @if (office.phoneNumber) {
        <div class="location-item">
          <svg-icon src="/assets/icons/call.svg" class="hidden-print"></svg-icon>
          <a
            [href]="
              office.phoneNumber.phoneNumberWithoutExtension | clickableTelephoneNumber: office.phoneNumber.extension
            "
            class="underline-link link-phone">
            <strong class="hidden-screen">{{ t('Phone') }} -</strong>
            {{ office.phoneNumber.phoneNumberWithoutExtension }}
            @if (office.phoneNumber.extension) {
              <span>ext. {{ office.phoneNumber.extension }}</span>
            }
          </a>
        </div>
      }
      @if (office.email) {
        <div class="location-item">
          <svg-icon src="/assets/icons/mail.svg" class="hidden-print"></svg-icon>
          <a
            [href]="office.email | emailLink: 'Response Requested: Seeking Program Information'"
            class="underline-link link-email">
            <strong class="hidden-screen">{{ 'Email' }} -</strong>
            {{ office.email }}
          </a>
        </div>
      }
      @if (office.fax) {
        <div class="location-item">
          <svg-icon src="/assets/icons/fax.svg" class="hidden-print"></svg-icon>
          <p>{{ t('Fax') }}: {{ office.fax }}</p>
        </div>
      }
    </div>
    <kp-local-resource-hours class="hours" [hours]="office.formattedHours"></kp-local-resource-hours>
  </div>
  @if (office.notes) {
    <p class="location-item notes">
      <svg-icon src="/assets/icons/notes.svg" class="hidden-print"></svg-icon>
      {{ office.notes }}
    </p>
  }

  <ng-template #location let-office="office">
    <p>{{ office?.address1 }}</p>
    @if (office.address2) {
      <p>{{ office.address2 }}</p>
    }
    <p>
      {{ office?.city ? office.city + ', ' : false }}
      {{ office?.state ? office.state + ' ' : false }} {{ office.postal }}
    </p>
    @if (office?.distance && !office?.isAdministrative) {
      <p class="distance hidden-screen">
        {{ t('distance miles', { distance: office.distance }) }}
      </p>
    }
  </ng-template>
</ng-container>
