<ng-container *transloco="let t">
  <header class="top-header">
    <div class="header-container-logo">
      <button
        class="nav-menu-button hidden-print"
        [ngClass]="{ active: navActive && !quickActionsOpen }"
        (click)="onNavBtnClick()"
        [attr.aria-label]="t('Side Navigation Toggle')"
        (keydown.tab)="sideNavTab($event)">
        <span class="nav-menu-icon"></span>
      </button>
      <a routerLink="/" (click)="quickActionsToggled.emit(false)" class="logo" [attr.aria-label]="productName">
        <svg-icon [src]="(themeService.theme$ | async)?.iconUrl"></svg-icon>
      </a>
    </div>
    <kp-core-nav
      class="nav-component-container hidden-print"
      [quickActionsOpen]="quickActionsOpen"
      (quickActionsToggled)="quickActionsToggled.emit($event)"></kp-core-nav>
    <div class="header-container-tertiary-visible">
      @if (externalProduct === product.purposeful) {
        <kp-purposeful-days (purposefulDaysClicked)="quickActionsToggled.emit(false)"></kp-purposeful-days>
      }
      <div class="header-container-tertiary hidden-print">
        @if (externalProduct === product.resourceful) {
          <a
            routerLink="/cards/saved"
            (click)="quickActionsToggled.emit(false)"
            routerLinkActive="header-active"
            class="button-icon"
            [attr.aria-label]="t('Saved cards')">
            <svg-icon src="/assets/icons/save-filled.svg"></svg-icon>
          </a>
          <a
            routerLink="/feedback"
            (click)="quickActionsToggled.emit(false)"
            routerLinkActive="header-active"
            class="button-icon"
            [attr.aria-label]="t('Feedback')">
            <svg-icon src="/assets/icons/feedback.svg"></svg-icon>
          </a>
        }
        <a
          routerLink="/help"
          (click)="quickActionsToggled.emit(false)"
          routerLinkActive="header-active"
          class="button-help"
          [attr.aria-label]="t('Help')">
          <svg-icon src="/assets/icons/question-filled-circle.svg"></svg-icon>
        </a>
        @if (navigationBloc.moreApps$ | async; as moreApps) {
          @if (moreApps.length > 1) {
            <kui-app-switcher
              class="app-switcher-wrap"
              [apps]="moreApps"
              (appClicked)="appClicked($event)"
              (appDrawerOpened)="quickActionsToggled.emit(false)"></kui-app-switcher>
          }
        }
      </div>
    </div>
  </header>
</ng-container>
