<ng-container *transloco="let t">
  @if (!showConfirmDeletion) {
    <button
      class="icon-delete-button"
      [attr.aria-label]="t('Delete photo')"
      (keyup.enter)="deleteImageIntent()"
      (keyup.space)="deleteImageIntent()"
      (click)="deleteImageIntent()">
      <svg-icon class="icon-delete" src="assets/icons/trash.svg"></svg-icon>
    </button>
  }
  <kp-carousel class="image-carousel" [options]="sliderOptions" [plugins]="[MutationPlugin, ResizePlugin]">
    @for (image of images; track image) {
      <div class="carousel-image-wrap">
        <img
          class="carousel-image"
          [src]="image?.urlOptimized"
          [alt]="
            t('Uploaded image, original filename was originalFilename', { originalFilename: image?.originalFilename })
          " />
      </div>
    }
  </kp-carousel>
  @if (!showConfirmDeletion) {
    <kp-bottom-arrow-nav
      class="bottom-nav"
      [showsBackButton]="showNavButtons"
      (backClick)="slider?.prev?.()"
      backAriaLabel="Previous image"
      [showsNextButton]="showNavButtons"
      (nextClick)="slider?.next?.()"
      nextAriaLabel="Next image"
      [showsContent]="true"
      [showFade]="false">
      <a
        routerLink="/me/photos"
        class="link-view-all"
        [attr.aria-label]="t('View all user images')"
        (click)="checkCurrentPage($event)">
        {{ t('View All') }}
      </a>
    </kp-bottom-arrow-nav>
  }

  @if (showConfirmDeletion) {
    <div class="confirm-deletion-popup">
      <p class="confirm-remove-question" aria-live="polite">
        {{ t('Are you sure you want to remove this image from ' + product + '?') }}
      </p>
      <div class="delete-confirmation-buttons">
        <button
          class="button-delete-cancel"
          [attr.aria-label]="t('Cancel image deletion')"
          (keyup.enter)="deleteImage(false)"
          (keyup.space)="deleteImage(false)"
          (click)="deleteImage(false)"
          [disabled]="deletingImage">
          {{ t('Cancel') }}
        </button>
        <button
          class="button button-pill button-reverse button-w-icon button-delete-confirm"
          [attr.aria-label]="t('Remove image')"
          (keyup.enter)="deleteImage(true)"
          (keyup.space)="deleteImage(true)"
          (click)="deleteImage(true)"
          [disabled]="deletingImage">
          <svg-icon src="assets/icons/trash.svg" class="button-icon"></svg-icon>
          <span>{{ t('Remove') }}</span>
        </button>
      </div>
    </div>
  }
</ng-container>
