<ng-container *transloco="let t">
  <kp-like-icon
    class="icon-action"
    [preference]="preference"
    [likeCount]="card?.userState?.likeCount"
    [color]="color"
    (likeChanged)="moreOrLess(isLocalResource ? cardEventType.LOCAL_RESOURCE_MORE : cardEventType.MORE)"></kp-like-icon>

  <div class="icon-action save-icon" [tp]="saveTooltip" tpTrigger="manual" [tpIsVisible]="displaySavedTooltip">
    <kp-save-icon
      [card]="card"
      [requestType]="requestType"
      [screen]="screen"
      [cardDisplayType]="cardDisplayType.detailed"
      [isSaved]="card?.userState?.saveState?.isSaved"
      [color]="color"
      (showSaveTooltip)="showSaveTooltip($event)"
      (emittedUnsavedState)="cardUnsaved.emit()"></kp-save-icon>
  </div>

  <ng-template #saveTooltip>
    <div>
      {{ t('Added to') }}
      <a routerLink="/cards/saved" class="save-tooltip-link">{{ t('Saved') }}</a>
    </div>
  </ng-template>
</ng-container>
