import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-detail-view-action',
  templateUrl: './detail-view-action.component.html',
  styleUrls: ['./detail-view-action.component.scss'],
})
export class DetailViewActionComponent {
  @Input() infoText: string; // NOTE: Translate before passing in
  @Input() primaryIconUrl: string;
  @Input() saveDisabled = false;
  @Input() showEditableAction = false;
  @Input() showCompactAction = false;
  @Input() showInfo = false;
  @Input() title: string;

  @Output() primaryIconClicked = new EventEmitter<void>();
  @Output() saveClicked = new EventEmitter<void>();
  @Output() trashIconClicked = new EventEmitter<void>();

  expandInfo() {
    this.showInfo = !this.showInfo;
  }
}

export const MockDetailViewActionComponent = MockComponent({
  selector: 'kp-detail-view-action',
  inputs: [
    'infoText',
    'primaryIconUrl',
    'saveDisabled',
    'showEditableAction',
    'showCompactAction',
    'showInfo',
    'title',
  ],
  template: '<ng-content></ng-content>',
});
