<ng-container *transloco="let t">
  <kp-detail-view-action
    [title]="t('Pair a Habit')"
    [infoText]="
      t(
        'Boost your chances of success by pairing this with something you already do – like your morning coffee, your lunch break, or your bedtime routine.'
      )
    "
    [showEditableAction]="showEditableHabit"
    [showCompactAction]="showCompactHabit"
    [primaryIconUrl]="primaryActionIcon"
    [showInfo]="card?.userState?.habits?.[0] == null"
    [saveDisabled]="habitText === card?.userState?.habits?.[0]?.value || habitText === ''"
    (primaryIconClicked)="updateHabit()"
    (trashIconClicked)="resetHabits()"
    (saveClicked)="saveHabit()">
    <ng-container expanded>
      <p class="habit-bold">{{ t('I will do this when') }}&hellip;</p>
      <kp-text-field
        class="habit-text-field"
        [maxCharacters]="80"
        [textValue]="habitText"
        (textAreaAnswer)="habitText = $event"></kp-text-field>
    </ng-container>
    <p class="habit-instructions" compact>
      {{ t('I will do this when') }}
      <strong class="habit-bold">{{ habitText }}</strong>
    </p>
  </kp-detail-view-action>
</ng-container>
