import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Document } from '@contentful/rich-text-types';
import { TranslocoService } from '@jsverse/transloco';
import { SocialChallenge, SocialChallengeState } from '@ktypes/models';
import { MockComponent } from '@kutil/test';
import { take } from 'rxjs/operators';

interface TertiaryChallengeInfo {
  ariaLabelState?: string;
  ariaLabelStatus?: string;
  state?: string;
  separator?: string;
  statusIcon?: string;
  status?: number | string;
}

@Component({
  selector: 'kf-sc-social-challenge',
  templateUrl: './social-challenge.component.html',
  styleUrls: ['./social-challenge.component.scss'],
})
export class SocialChallengeComponent implements OnInit, OnChanges {
  constructor(private _translocoService: TranslocoService) {}

  @Input() challenge: SocialChallenge;
  @Input() challengeCompletedToday: boolean; // used to trigger an update on challenge completion for today

  challengeState = SocialChallengeState;
  challengeUrl: string;
  motivationMessage: Document;
  showExtraneous: boolean;
  showImage: boolean;
  showMotivationMessage: boolean;
  showProgress: boolean;
  tertiaryChallengeInfo: TertiaryChallengeInfo;

  ngOnInit() {
    this.challengeUrl = `/challenges/${this.challenge?.id}`;
  }

  ngOnChanges(/* changes: SimpleChanges */) {
    if (this.challenge) {
      this._formatTertiaryInfo(this.challenge);
      this.showExtraneous = checkShowExtraneous(this.challenge);
      this.showImage = checkShowImage(this.challenge);
      this.showProgress = checkShowProgress(this.challenge);
      this.motivationMessage = this.challenge.individualGoalCompleted
        ? this.challenge.individual.completionMessage
        : this.challenge.individual.motivation;
      this.showMotivationMessage =
        this.motivationMessage &&
        (this.challenge.individualGoalCompleted || this.challenge.status.state !== SocialChallengeState.ended);
    }
  }

  private _formatTertiaryInfo(challenge: SocialChallenge): void {
    if (!challenge) {
      return null;
    }
    const individualStatus = challenge.individual.status;
    const groupStatus = challenge.group.status;
    let tertiaryChallengeInfo = null;
    switch (challenge.status.state) {
      case SocialChallengeState.active:
        if (individualStatus.enrolled) {
          tertiaryChallengeInfo = {
            ariaLabelState: `Challenge ${individualStatus.completedToday ? 'Completed Today!' : 'Not completed today'}`,
            state: `${individualStatus.completedToday ? 'Completed Today!' : 'Not completed today'}`,
          };
        } else {
          tertiaryChallengeInfo = {
            ariaLabelState: 'Challenge Not Joined',
            state: 'Not Joined',
            separator: ' • ',
            ariaLabelStatus: `participating challenge participants`,
            status: groupStatus.participating,
            statusIcon: '/assets/icons/users-group.svg',
          };
        }
        break;
      case SocialChallengeState.upcoming:
        tertiaryChallengeInfo = {
          ariaLabelState: `Challenge ${individualStatus.enrolled ? 'Joined' : 'Not Joined'}`,
          state: `${individualStatus.enrolled ? 'Joined' : 'Not Joined'}`,
          separator: ' • ',
          ariaLabelStatus: 'Not Started',
          status: 'Not Started',
        };
        break;
      case SocialChallengeState.ended:
        tertiaryChallengeInfo = {
          ariaLabelState: 'Challenge Ended',
          state: 'Ended',
          ariaLabelStatus: 'Ended',
        };
        break;
      default:
        tertiaryChallengeInfo = null;
        break;
    }
    if (tertiaryChallengeInfo != null) {
      /**
       * t(f_sc.participating challenge participants)
       * t(f_sc.Completed Today!)
       * t(f_sc.Not completed today)
       * t(f_sc.Challenge Not completed today)
       * t(f_sc.Challenge Joined)
       * t(f_sc.Challenge Not Joined)
       * t(f_sc.Challenge Ended)
       * t(f_sc.Not Joined)
       * t(f_sc.Not Started)
       * t(f_sc.Joined)
       * t(f_sc.Ended)
       */
      this._translocoService
        .selectTranslate(
          [
            tertiaryChallengeInfo.ariaLabelState ?? '',
            tertiaryChallengeInfo.state ?? '',
            tertiaryChallengeInfo.ariaLabelStatus ?? '',
          ].filter((key) => !!key),
          {
            participating: groupStatus.participating,
          },
          { scope: 'features.social-challenges', alias: 'f_sc' }
        )
        .pipe(take(1))
        .subscribe(([translatedAriaLabelState, state, ariaLabelStatus]: string[]) => {
          this.tertiaryChallengeInfo = {
            ...tertiaryChallengeInfo,
            ...{
              ariaLabelState: translatedAriaLabelState,
              state: state,
              ariaLabelStatus: ariaLabelStatus,
            },
          };
        });
    } else {
      this.tertiaryChallengeInfo = null;
    }
  }
}

export const MockSocialChallengeComponent = MockComponent({
  selector: 'kf-sc-social-challenge',
  inputs: ['challenge', 'challengeCompletedToday'],
});

// export so it can be tested
export function checkShowExtraneous(challenge: SocialChallenge) {
  if (!challenge) {
    return false;
  }
  return (
    !challenge.individual.status.enrolled ||
    (!challenge.individual.status.completedToday && !challenge.individualGoalCompleted)
  );
}

export function checkShowImage(challenge: SocialChallenge) {
  if (!challenge) {
    return false;
  }
  return (
    challenge.image?.url != null &&
    challenge.status.state !== SocialChallengeState.ended &&
    checkShowExtraneous(challenge)
  );
}

export function checkShowProgress(challenge: SocialChallenge) {
  if (!challenge) {
    return false;
  }
  return challenge.status.state !== SocialChallengeState.upcoming;
}
