<ng-container *transloco="let t">
  @if (challenge) {
    <a
      class="challenge-wrap"
      role="region"
      aria-label="Challenge"
      [ngClass]="{
        'has-motivation-message': showMotivationMessage,
        'ended': challenge.status.state === challengeState.ended
      }"
      [routerLink]="challengeUrl">
      <div class="challenge">
        @if (challenge.status.state !== challengeState.ended && showExtraneous) {
          <kf-sc-social-challenge-label class="challenge-label" [challenge]="challenge"></kf-sc-social-challenge-label>
        }
        @if (showImage) {
          <div class="challenge-image hidden-print">
            <img [src]="challenge.image.url + '?h=400'" [alt]="challenge.image.description" />
          </div>
        }
        <div class="challenge-info">
          <div class="challenge-details">
            <h2 class="title">
              {{ challenge.title }}
            </h2>
            @if (tertiaryChallengeInfo) {
              <div class="tertiary-challenge-info" [attr.aria-label]="t('f_sc.Challenge Status')">
                @if (tertiaryChallengeInfo.state) {
                  <span [attr.aria-label]="tertiaryChallengeInfo.ariaLabelState">
                    <span aria-hidden="true">{{ tertiaryChallengeInfo.state }}</span>
                  </span>
                }
                @if (tertiaryChallengeInfo.separator) {
                  <span>{{ tertiaryChallengeInfo.separator }}</span>
                }
                @if (tertiaryChallengeInfo.statusIcon) {
                  <span>
                    <svg-icon [src]="tertiaryChallengeInfo.statusIcon"></svg-icon>
                  </span>
                }
                @if (tertiaryChallengeInfo.status) {
                  <span [attr.aria-label]="tertiaryChallengeInfo.ariaLabelStatus">
                    <span aria-hidden="true">{{ tertiaryChallengeInfo.status }}</span>
                  </span>
                }
              </div>
            }
          </div>
          @if (challenge.status.state !== challengeState.upcoming && challenge.individual.status.enrolled) {
            <kf-sc-social-challenge-progress
              class="challenge-progress-visual"
              [groupGoal]="challenge.group.goal"
              [groupProgress]="challenge.group.status.progress?.count"
              [individualGoal]="challenge.individual.goal"
              [individualProgress]="challenge.individual.status.progress?.count"
              [individualDayComplete]="challenge.individual.status.completedToday"
              [individualCompleted]="challenge.individualGoalCompleted"></kf-sc-social-challenge-progress>
          }
        </div>
      </div>
      @if (showMotivationMessage) {
        <div class="motivation-message">
          <svg-icon src="/assets/icons/mountain-summit-flag-no-border.svg"></svg-icon>
          <kui-rich-text
            class="message"
            [richText]="motivationMessage"
            [onBackground]="true"
            [translate]="false"></kui-rich-text>
        </div>
      }
    </a>
  }
</ng-container>
