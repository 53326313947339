import { Injectable } from '@angular/core';
import { ImageRecord } from '@kp/shared/image/image-record.model';
import { ImageApi } from '@kp/shared/image/image.api';
import { DataStatus, Status } from '@ktypes/models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

export enum ImageType {
  latest = 'latest', // local use only, is a user_cover_image
  random = 'random', // local use only, is a user_cover_image
  user_cover_image = 'user_cover_image',
  user_profile = 'user_profile',
  user_purpose = 'user_purpose',
}

export enum PhotoState {
  adding,
  failedToAdd,
  failedToLoad,
  loaded,
  loading,
  noImage,
}

export enum CropImageCloseOptions {
  cancel,
  crop,
  error,
  no_crop,
}

export interface CurrentImageState {
  currentPhotoState: PhotoState;
  addingOrLoading: boolean;
}

interface PhotoModalDetails {
  carouselImagesType: ImageType;
  currentImageType: ImageType;
  image?: ImageRecord;
  selectedImageId: string;
}

@Injectable({
  providedIn: 'root',
})
export class ImageBloc {
  constructor(private _imageApi: ImageApi) {}

  // random and latest are not ImageTypes the backend is aware of
  private _imageSubjects = new Map<ImageType, BehaviorSubject<DataStatus<ImageRecord | ImageRecord[]>>>([
    [ImageType.random, new BehaviorSubject<DataStatus<ImageRecord>>(null)],
    [ImageType.latest, new BehaviorSubject<DataStatus<ImageRecord>>(null)],
    [ImageType.user_cover_image, new BehaviorSubject<DataStatus<ImageRecord[]>>(null)],
    [ImageType.user_profile, new BehaviorSubject<DataStatus<ImageRecord>>(null)],
  ]);
  private _photoModalDetails$ = new Subject<PhotoModalDetails>();
  private _showPhotoModal$ = new Subject<boolean>();

  photoModalDetails$: Observable<PhotoModalDetails> = this._photoModalDetails$.asObservable();
  showPhotoModal$: Observable<boolean> = this._showPhotoModal$.asObservable();

  private _uploadStatusSubjects = new Map<ImageType, BehaviorSubject<Status>>([
    [ImageType.user_cover_image, new BehaviorSubject<Status>(null)],
    [ImageType.user_profile, new BehaviorSubject<Status>(null)],
  ]);

  latestImage$: Observable<ImageRecord> = this.imageStatus$(ImageType.latest, 1).pipe(
    map((imageStatus) => {
      return [Status.done, Status.local].includes(imageStatus?.status) ? (imageStatus?.data as ImageRecord) : null;
    })
  );

  randomImage$: Observable<ImageRecord> = this.imageStatus$(ImageType.random, 1).pipe(
    map((imageStatus) => {
      return [Status.done, Status.local].includes(imageStatus?.status) ? (imageStatus?.data as ImageRecord) : null;
    })
  );

  profileImage$: Observable<ImageRecord> = this.imageStatus$(ImageType.user_profile, 1).pipe(
    map((imageStatus) => {
      return [Status.done, Status.local].includes(imageStatus?.status) ? (imageStatus.data as ImageRecord) : null;
    })
  );

  userCoverImages$: Observable<ImageRecord[]> = this.imageStatus$(ImageType.user_cover_image).pipe(
    map((imageStatus) => {
      if ([Status.done, Status.local].includes(imageStatus?.status)) {
        return Array.isArray(imageStatus?.data) ? imageStatus?.data : [imageStatus?.data];
      }
      return [];
    })
  );

  static translateImageType(imageType: ImageType): ImageType {
    // random and latest are not ImageTypes the backend is aware of,
    // and user_purpose is a legacy type treated the same as user_cover_image now
    if ([ImageType.user_purpose, ImageType.random, ImageType.latest].includes(imageType)) {
      return ImageType.user_cover_image;
    }
    return imageType;
  }

  imageUploadStatus$(type: ImageType): Observable<Status> {
    return this._uploadStatusSubjects.get(ImageBloc.translateImageType(type));
  }

  clearImageUploadStatus(type: ImageType) {
    this._uploadStatusSubjects.get(ImageBloc.translateImageType(type)).next(null);
  }

  openPhotoModal(photoModalDetails: PhotoModalDetails) {
    this._photoModalDetails$.next(photoModalDetails);
    this._showPhotoModal$.next(true);
  }

  closePhotoModal() {
    this._showPhotoModal$.next(false);
    this._photoModalDetails$.next(null);
  }

  nUserCoverImages$(numberOfImages?: number): Observable<ImageRecord[]> {
    return this.imageStatus$(ImageType.user_cover_image, numberOfImages).pipe(
      map((imageStatus) => {
        if (imageStatus?.status === Status.done) {
          return Array.isArray(imageStatus?.data) ? imageStatus?.data : [imageStatus?.data];
        }
        return [];
      })
    );
  }

  /**
   * imageStatus$
   * get observable stream of images stored in the imageSubjects Map of the provided type
   *
   * @param type - ImageType
   * @param numberOfImages - leave blank/undefined for all; if requested > available, will return all available
   */
  imageStatus$(type: ImageType, numberOfImages?: number): Observable<DataStatus<ImageRecord | ImageRecord[]>> {
    return this._imageSubjects.get(type).pipe(
      map((imageStatus) => {
        if (imageStatus && numberOfImages > 0) {
          const images: ImageRecord[] = Array.isArray(imageStatus?.data)
            ? imageStatus.data.slice(0, numberOfImages)
            : [imageStatus.data];
          if (images?.length > 1) {
            return new DataStatus<ImageRecord[]>(imageStatus.status, imageStatus.message, images);
          } else if (images?.length === 1) {
            return new DataStatus<ImageRecord>(imageStatus.status, imageStatus.message, images[0]);
          }
        }
        return imageStatus;
      })
    );
  }

  imageById$(type: ImageType, imageId: string): Observable<DataStatus<ImageRecord>> {
    return this.imageStatus$(type, 1).pipe(
      filter(
        (imageStatus) =>
          imageStatus?.data != null &&
          (Array.isArray(imageStatus.data) ? imageStatus.data : [imageStatus.data])?.some((img) => img?.id === imageId)
      ),
      map((imageStatus) => {
        const imageRecord = (Array.isArray(imageStatus.data) ? imageStatus.data : [imageStatus.data]).find(
          (img) => (img.id = imageId)
        );
        return imageRecord ? new DataStatus(imageStatus.status, imageStatus.message, imageRecord) : null;
      })
    );
  }

  fetchLatestImage(type = ImageType.latest, isInitial = true): void {
    const currentImageStatus = this._imageSubjects.get(type).getValue()?.data ?? null;
    const latestImageRecord: ImageRecord = Array.isArray(currentImageStatus)
      ? currentImageStatus[0]
      : currentImageStatus;
    this._imageSubjects
      .get(type)
      .next(
        new DataStatus<ImageRecord>(
          latestImageRecord != null && !isInitial ? Status.local : Status.starting,
          latestImageRecord
        )
      );
    this._refreshImage(type, 'latest', false);
  }

  fetchImageById(imageId: string, type = ImageType.user_cover_image) {
    // TODO: Update to use an API endpoint that allows getting image specifically by ID once that exists
    this._refreshImage(type, 'multiple');
    if (type !== ImageType.latest && imageId) {
      // if not latest, also put the requested image in the latest stream
      this._imageSubjects
        .get(type)
        .pipe(
          filter((imageStatus) => [Status.done, Status.local].includes(imageStatus?.status)),
          take(1)
        )
        .subscribe((imageStatus) => {
          const imageRecord = (Array.isArray(imageStatus?.data) ? imageStatus.data : [imageStatus.data]).find(
            (img) => img.id === imageId
          );
          if (imageRecord) {
            this._imageSubjects
              .get(ImageType.latest)
              .next(new DataStatus(imageStatus.status, imageStatus.message, imageRecord));
          } else {
            this._imageSubjects.get(ImageType.latest).next(new DataStatus(Status.error));
          }
        });
    }
  }

  fetchImages(type = ImageType.user_cover_image) {
    this._refreshImage(type, 'multiple');
  }

  fetchRandomImage(type = ImageType.random) {
    this._refreshImage(type, 'random');
  }

  fetchProfileImage(type = ImageType.user_profile) {
    this._refreshImage(type, 'latest');
  }

  uploadImage(image: File | Blob, type: ImageType, allowRefresh = true): void {
    const normalizedType =
      type === ImageType.user_purpose || !this._imageSubjects.get(type) ? ImageType.user_cover_image : type;
    const currentImages = this._imageSubjects.get(normalizedType)?.getValue()?.data;
    const numberCurrentImages = Array.isArray(currentImages) ? currentImages?.length : 1;
    const imageRecord = new ImageRecord().deserialize({ imageType: type, signedUrl: URL.createObjectURL(image) });
    // Always update latestImage with the new image as local
    this._imageSubjects.get(ImageType.latest)?.next(new DataStatus<ImageRecord>(Status.local, imageRecord));
    if (normalizedType !== ImageType.user_cover_image) {
      // just replace the single image when it is not a user_cover_image
      this._imageSubjects.get(normalizedType)?.next(new DataStatus<ImageRecord>(Status.local, imageRecord));
    } else {
      // Don't clear array of images, just add to it (or make a new one if first image)
      this._imageSubjects
        .get(normalizedType)
        .next(
          new DataStatus<ImageRecord[]>(
            Status.local,
            Array.isArray(currentImages) ? [imageRecord, ...currentImages] : [imageRecord]
          )
        );
    }
    this._imageApi.uploadUserImage(image, ImageBloc.translateImageType(type)).then((imageUploadResult) => {
      this._uploadStatusSubjects.get(ImageBloc.translateImageType(type)).next(imageUploadResult?.status);
      if (imageUploadResult?.status === Status.done) {
        const newImage = imageUploadResult.data ?? imageRecord;
        let normalizedData: ImageRecord | ImageRecord[] = newImage;
        // Always update latestImage with the new image details
        this._imageSubjects
          .get(ImageType.latest)
          ?.next(new DataStatus<ImageRecord>(imageUploadResult.status, imageUploadResult.message, normalizedData));
        if (normalizedType === ImageType.user_cover_image) {
          if (currentImages == null) {
            normalizedData = [newImage];
          } else if (Array.isArray(currentImages) && numberCurrentImages >= 0) {
            normalizedData = [newImage, ...currentImages.filter((image) => image?.id !== newImage?.id)];
          } else if (!Array.isArray(currentImages) && numberCurrentImages === 1) {
            normalizedData = [newImage, currentImages];
          }
        }
        const normalizedDataStatus = new DataStatus<ImageRecord | ImageRecord[]>(
          imageUploadResult.status,
          imageUploadResult.message,
          normalizedData
        );
        this._imageSubjects.get(normalizedType).next(normalizedDataStatus);

        if (
          allowRefresh &&
          type !== ImageType.user_cover_image &&
          ImageBloc.translateImageType(type) === ImageType.user_cover_image
        ) {
          // if the image type was not user_cover_image but is considered one from the server, refresh if allowed
          this.fetchImages();
        }
      } else if (imageUploadResult?.status === Status.error) {
        // wait 4 seconds, fetch saved image(s) and restore
        console.warn('fetching images due to error');
        setTimeout(() => this.fetchImages(type), 4000);
      }
    });
  }

  deleteImage(imageId: string, imageType: ImageType) {
    if (!imageId) {
      console.warn('no imageId passed, cannot delete image');
      return;
    }
    this._imageApi.deleteUserImage(imageId).then((imageDeleteResult) => {
      if (imageDeleteResult?.status === Status.done) {
        const imageStatus = this._imageSubjects.get(imageType).getValue();
        // Always update latestImage with the new image details
        this._imageSubjects.get(ImageType.latest)?.next(null);
        // wait for change detection to settle, then update streams
        if (imageType === ImageType.user_cover_image) {
          const coverImages = Array.isArray(imageStatus?.data) ? imageStatus?.data : [imageStatus?.data];
          const updatedCoverImages: ImageRecord[] = coverImages?.filter((image) => image.id !== imageId);
          this._imageSubjects
            .get(imageType)
            .next(new DataStatus<ImageRecord[]>(imageStatus.status, imageStatus.message, updatedCoverImages));
        } else {
          this._imageSubjects
            .get(imageType)
            .next(new DataStatus<ImageRecord[]>(imageStatus.status, imageStatus.message, null));
        }
      }
    });
  }

  private _refreshImage(type: ImageType, optionKey: string, setStarting = true) {
    this.clearImageUploadStatus(ImageBloc.translateImageType(type));
    if (setStarting) {
      this._imageSubjects.get(type).next(new DataStatus(Status.starting));
    }
    this._imageApi.refreshImages(ImageBloc.translateImageType(type), { [optionKey]: true }).then((imageStatus) => {
      if (imageStatus?.status === Status.done) {
        this._imageSubjects.get(type).next(imageStatus);
      }
    });
  }
}
