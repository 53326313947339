<ng-container *transloco="let t">
  <div class="reflection-container">
    <svg-icon src="assets/icons/icons-nav-progress.svg" class="icon-progress"></svg-icon>
    <h3 class="reflection-topic">{{ t('Tracking Your Progress') }}</h3>
    <p class="reflection-content" [innerHTML]="t('firstReflectionCompletedModal.content')"></p>
    <a class="button button-reverse button-pill link-progress" routerLink="/progress" (click)="closeClicked.emit()">
      {{ t('Go to Progress') }}
    </a>
    <button class="button-close-modal" (click)="closeClicked.emit()">{{ t('Not Now') }}</button>
  </div>
</ng-container>
