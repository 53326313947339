import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { StreakBloc } from '@kp/streaks/streak.bloc';

@Component({
  selector: 'kp-modal-purposeful-days',
  templateUrl: './modal-purposeful-days.component.html',
  styleUrls: ['./modal-purposeful-days.component.scss'],
})
export class ModalPurposefulDaysComponent implements OnInit {
  @Output() closeClicked = new EventEmitter<boolean>();

  constructor(public streakBloc: StreakBloc) {}

  ngOnInit() {
    this.streakBloc.refreshStreaks();
  }

  onAnimationStart() {
    setTimeout(() => this.closeClicked.emit(true), 2000);
  }
}
