import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DialogueApi } from '@kp/dialogue/dialogue.api';
import { DialogueBlockEvent, DialogueBlockEventType } from '@kp/dialogue/models/dialogue-block-event.model';
import { DialogueBlock } from '@kp/dialogue/models/dialogue-block.model';
import { DialogueDataStatus } from '@kp/dialogue/models/dialogue-data-status.model';
import { DAILY_REFLECTION_KEYS, Dialogue, DialogueLogicKey, KumanuKeyType } from '@kp/dialogue/models/dialogue.model';
import { HttpStatusCode } from '@ktypes/enums';
import { DataStatus, JsonObject, Status, StatusMessage } from '@ktypes/models';
import { DateTimeUtil } from '@kutil';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DialogueBloc {
  constructor(private _dialogueApi: DialogueApi) {}

  private _dialogue = new BehaviorSubject<DataStatus<Dialogue>>(null);
  private _dialogueStatus = new BehaviorSubject<Map<string, DataStatus<DialogueDataStatus>>>(null);
  private _completedReflectionStatus = new BehaviorSubject<boolean>(false);

  get dialogue$(): Observable<DataStatus<Dialogue>> {
    return this._dialogue.asObservable();
  }

  get dialogue(): DataStatus<Dialogue> {
    return this._dialogue.value;
  }

  get currentDialogueBlock$(): Observable<DialogueBlock> {
    return this._dialogue.pipe(map((dialogueDataStatus) => dialogueDataStatus?.data?.dialogueBlock));
  }

  get currentDialogueBlock(): DialogueBlock {
    return this._dialogue.value?.data?.dialogueBlock;
  }

  get dialogueStatus$(): Observable<Map<string, DataStatus<DialogueDataStatus>>> {
    return this._dialogueStatus.asObservable();
  }

  get firstReflectionCompleted$(): Observable<boolean> {
    return this._completedReflectionStatus.asObservable();
  }

  getDialogueStatus(logicKey: DialogueLogicKey): void {
    if (logicKey == null) {
      return;
    }
    const initialDialogueStatus = new Map(this._dialogueStatus.value);
    initialDialogueStatus.set(logicKey, new DataStatus(Status.starting));
    this._dialogueStatus.next(initialDialogueStatus);
    this._dialogueApi.getDialogueStatus(logicKey).then((dialogueStatusDataStatus) => {
      // grabbing dialogueStatus again because it may have changed in the time the API call was happening
      const updatedDialogueStatus = new Map(this._dialogueStatus.value);
      updatedDialogueStatus.set(logicKey, dialogueStatusDataStatus);
      this._dialogueStatus.next(updatedDialogueStatus);
    });
  }

  createDialogue(
    logicKey: DialogueLogicKey,
    keyType: KumanuKeyType = KumanuKeyType.logicKey,
    refKey?: string,
    showPreviousAnswers = false
  ): void {
    if (this._dialogue?.value?.status === Status.starting) {
      // don't create a new dialogue if it's already in process (could be from a previous create call, or a block event)
      return;
    }
    this._dialogue.next(new DataStatus<Dialogue>(Status.starting, null, null));
    this._dialogueApi.createDialogueSession(logicKey, keyType, refKey, showPreviousAnswers).then((dialogue) => {
      const hasDialogueData = dialogue != null;
      const status = hasDialogueData ? Status.done : Status.error;
      const statusMessage = new StatusMessage(
        hasDialogueData ? HttpStatusCode.OK : HttpStatusCode.BAD_REQUEST,
        hasDialogueData ? 'OK' : 'Error creating dialogue'
      );
      this._dialogue.next(new DataStatus<Dialogue>(status, statusMessage, dialogue));
    });
  }

  getExistingDialogue(dialogueId: string) {
    if (this._dialogue?.value?.status === Status.starting) {
      // don't create a new dialogue if it's already in process (could be from a previous create call, or a block event)
      return;
    }
    this._dialogue.next(new DataStatus<Dialogue>(Status.starting, null, null));
    this._dialogueApi.getDialogue(dialogueId).then((dialogue) => {
      const hasDialogueData = dialogue != null;
      const status = hasDialogueData ? Status.done : Status.error;
      const statusMessage = new StatusMessage(
        hasDialogueData ? HttpStatusCode.OK : HttpStatusCode.BAD_REQUEST,
        hasDialogueData ? 'OK' : 'Error getting dialogue'
      );
      this._dialogue.next(new DataStatus<Dialogue>(status, statusMessage, dialogue));
    });
  }

  clearDialogueStreams(clearDialogue = true, clearDialogueStatus = true, dialogueKey?: DialogueLogicKey) {
    if (clearDialogue) {
      this._dialogue.next(null);
    }
    if (clearDialogueStatus) {
      if (!dialogueKey) {
        this._dialogueStatus.next(null);
      } else {
        const dialogueStatusMap = new Map(this._dialogueStatus.value);
        dialogueStatusMap.delete(dialogueKey);
        this._dialogueStatus.next(dialogueStatusMap);
      }
    }
  }

  clearDialogueStreamsIfError(clearDialogue = true, clearDialogueStatus = true, dialogueKey?: DialogueLogicKey) {
    const clearDialogueIfNoError = dialogueKey && DAILY_REFLECTION_KEYS.includes(dialogueKey);
    this.clearDialogueStreams(
      clearDialogue && (clearDialogueIfNoError || this._dialogue.value?.status === Status.error),
      clearDialogueStatus && (!dialogueKey || this._dialogueStatus.value?.get(dialogueKey)?.status === Status.error),
      dialogueKey
    );
  }

  updateBlockStatus(
    dialogueId: string,
    dialogueBlockId: string,
    eventJson: JsonObject = {},
    eventType = DialogueBlockEventType.completed
  ): void {
    // May not always have access to the dialogueId/dialogueBlockId from places this method is called
    // so try to look them up if they are falsy
    dialogueId = dialogueId || this._dialogue?.value?.data?.id;
    dialogueBlockId = dialogueBlockId || this.currentDialogueBlock?.id;
    this._dialogue.next(new DataStatus<Dialogue>(Status.starting, null, null));
    const dialogueBlockEvent = new DialogueBlockEvent(eventType, DateTimeUtil.formatInLocal(), eventJson);
    this._dialogueApi
      .createDialogueBlockEvent(dialogueBlockEvent, dialogueId, dialogueBlockId)
      .then((dialogue: Dialogue) => {
        const hasDialogueData = dialogue != null;
        const status = hasDialogueData ? Status.done : Status.error;
        const statusMessage = new StatusMessage(
          hasDialogueData ? HttpStatusCode.OK : HttpStatusCode.BAD_REQUEST,
          hasDialogueData ? 'OK' : 'Error updating dialogue block status'
        );
        if (!hasDialogueData) {
          console.warn('Error updating dialogue block status');
        }
        this._dialogue.next(new DataStatus<Dialogue>(status, statusMessage, dialogue));
      })
      .catch((err: HttpErrorResponse) => {
        console.warn('Error updating dialogue block status', err);
        this._dialogue.next(
          new DataStatus<Dialogue>(
            Status.error,
            new StatusMessage(HttpStatusCode.INTERNAL_SERVER_ERROR, err.message),
            null
          )
        );
      });
  }

  firstReflectionCompleted() {
    if (!this._completedReflectionStatus.value) {
      this._completedReflectionStatus.next(true);
    }
  }
}
