<ng-container *transloco="let t">
  <div class="footer-container">
    @if (showFade) {
      <div class="fade-container" [ngClass]="{ 'no-large-fade': !showLargeFade }"></div>
    }
    @if (showsBackButton || showsNextButton || showsSkip || showsContent) {
      <!--
        t(back)
        t(continue)
        t(Next image)
        t(Previous image)
        t(skip)
        t(Skip)
      -->
      <div class="container" [ngClass]="{ 'nav-diffHeader': showNav }">
        @if (!showsBackButton) {
          <div></div>
        }
        @if (showsBackButton) {
          <button
            (click)="back()"
            [disabled]="disableBackButton"
            class="arrow-button"
            [attr.aria-label]="t(backAriaLabel)"
            data-e2e="navPrevButton">
            <svg-icon src="/assets/icons/chevron-left-circle.svg"></svg-icon>
          </button>
        }
        <div class="projected-content"><ng-content></ng-content></div>
        @if (showsNextButton && !showsSkip) {
          <button
            (click)="next()"
            [disabled]="disableNextButton"
            class="arrow-button"
            [attr.aria-label]="t(nextAriaLabel)"
            data-e2e="navNextButton">
            <svg-icon src="/assets/icons/chevron-right-circle.svg"></svg-icon>
          </button>
        }
        @if (showsSkip) {
          <button
            [attr.aria-label]="t(skipAriaLabel)"
            (click)="next()"
            class="skip-button button button-pill button-secondary">
            {{ t(skipText) }}
          </button>
        }
        @if (!showsNextButton && !showsSkip) {
          <div></div>
        }
      </div>
    }
  </div>
</ng-container>
