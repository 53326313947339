import { Injectable } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { QuestApi } from '@kp/quest/quest.api';
import { CardCollectionService } from '@kp/shared/components/cards/card-collection.service';
import {
  CardItem,
  DataStatus,
  JsonObject,
  Quest,
  QuestEvent,
  QuestEventType,
  QuestItem,
  QuestKeyType,
  QuestRequest,
  Status,
  StatusMessage,
} from '@ktypes/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuestBloc {
  constructor(
    private _questApi: QuestApi,
    private _cardCollectionService: CardCollectionService,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {}

  private _questStreams = new Map<string, BehaviorSubject<DataStatus<Quest>>>(null);

  private _matchingItemsSubject: BehaviorSubject<DataStatus<Map<string, QuestItem>>> = new BehaviorSubject<
    DataStatus<Map<string, QuestItem>>
  >(null);

  getLatestQuestValue$(logicKey: string, questKeyType: QuestKeyType): Observable<DataStatus<Quest>> {
    if (!this._questStreams.get(logicKey)) {
      this.getQuest(new QuestRequest(logicKey, questKeyType));
    }
    return this._questStreams.get(logicKey).asObservable();
  }

  getQuest(questRequest: QuestRequest): void {
    this._addStreamAsNeeded(questRequest.key);
    this._questStreams.get(questRequest.key).next(new DataStatus<Quest>(Status.starting, null, null));
    this._questApi.requestQuest(questRequest.key, questRequest.keyType).then((quest: Quest) => {
      this._questStreams.get(questRequest.key).next(new DataStatus<Quest>(Status.done, null, quest));
      this._addQuestCardsToCollection(questRequest.key);
    });
  }

  createQuestEvent(event: QuestEvent): void {
    event.eventInfo.product = this._environmentVariablesService.product;
    void this._questApi.createQuestEvent(event);
  }

  completeQuestIfDone(card: CardItem, questOrQuestKey: Quest | string) {
    const quest = typeof questOrQuestKey === 'string' ? this._getQuestFromKey(questOrQuestKey) : questOrQuestKey;
    if (quest != null && quest.completedItemCount === quest.itemCount && card?.userState?.isCompleted != null) {
      void this.createQuestEvent(
        new QuestEvent(quest.id, QuestEventType.QUEST_COMPLETE, { quest_presented_id: quest.presentedId })
      );
    }
  }

  matchItemsToRefKey(refKey: string, questItem: QuestItem) {
    this._matchingItemsSubject.next(
      new DataStatus<Map<string, QuestItem>>(
        Status.done,
        new StatusMessage(200, 'OK'),
        new Map<string, QuestItem>([[refKey, questItem]])
      )
    );
  }

  private _getQuestFromKey(logicKey: string): Quest {
    return this._questStreams.get(logicKey)?.value?.data ?? null;
  }

  private _addStreamAsNeeded(logicKey: string): void {
    if (!this._questStreams.has(logicKey)) {
      this._questStreams.set(
        logicKey,
        new BehaviorSubject<DataStatus<Quest>>(new DataStatus<Quest>(Status.starting, null, null))
      );
    }
  }

  private _addQuestCardsToCollection(logicKey: string): void {
    const cardItems: JsonObject = {};
    if (this._questStreams.get(logicKey) && this._questStreams.get(logicKey).value.status === Status.done) {
      const questItems = this._questStreams.get(logicKey)?.value?.data?.questItems;
      if (questItems) {
        questItems.forEach((item) => (cardItems[item.card.id] = item.card));
      }
    }
    this._cardCollectionService.addCards(Object.values(cardItems));
  }
}
