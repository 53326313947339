import { APP_INITIALIZER } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { SettingsBloc, SharingBloc, TagBloc } from '@kbloc';
import { TranslationBloc } from '@kf-loc';
import { VersionBloc } from '@kp/version/version.bloc';
import { DataStoreService } from '@kservice';
import { Status } from '@ktypes/models';
import { combineLatest, firstValueFrom, of, switchMap } from 'rxjs';
import { filter, map, skipWhile, take } from 'rxjs/operators';

function preLoadAuthenticatedDataFactory(
  dataStoreService: DataStoreService,
  settingsBloc: SettingsBloc,
  sharingBloc: SharingBloc,
  tagBloc: TagBloc,
  translationBloc: TranslationBloc,
  transloco: TranslocoService,
  versionBloc: VersionBloc
): () => void {
  // stop supported browser timer from timing out and triggering unsupported browser page;
  // using window directly due to app state here
  if (window) {
    window['isSupportedBrowser'] = true;
  }
  return () => {
    // TODO: Refactor so this it either does not use firstValueFrom and directly returns an Observable (must complete)
    //  or simplify logic so it doesn't require waiting on multiple items
    return firstValueFrom(
      combineLatest([dataStoreService.authData$, dataStoreService.authStatus$, dataStoreService.user$]).pipe(
        // tap((args) => console.dir({ a_title: 'preAuthData', ...args })),
        skipWhile(
          ([authData, authStatus, user]) =>
            user?.type != null && (!authData?.token || authStatus?.status == null || authStatus?.status !== Status.done)
        ),
        switchMap(([, , user]) => {
          if (!user?.type) {
            // don't make authenticated calls if user or user.type is null
            return of(user);
          }
          settingsBloc.getUserSettings();
          return dataStoreService.user$;
        }),
        filter((user) => user?.type == null || !!user?.settings),
        map((user) => {
          if (user?.type == null) {
            // don't make authenticated calls if user or user.type is null;
            // and exit so Observable is not completed
            return;
          }

          // TODO: Consider extracting to a standalone function that can be used outside of app initialization
          tagBloc.getUserTags();
          versionBloc.checkAppVersion();
          sharingBloc.getInvites();

          // get supported languages, wait before proceeding
          translationBloc.getSupportedLanguages().then(() => {
            tagBloc.languageTranslation$.pipe(take(1)).subscribe((languageTranslation) => {
              if (languageTranslation) {
                translationBloc.checkUserLanguage();
                transloco.load(dataStoreService.signalStore?.currentLanguage());
              }
            });
          });
        })
      )
    );
  };
}

export const preLoadAuthenticatedData = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: preLoadAuthenticatedDataFactory,
  deps: [DataStoreService, SettingsBloc, SharingBloc, TagBloc, TranslationBloc, TranslocoService, VersionBloc],
};
