<ng-container *transloco="let t">
  <div class="celebration-content">
    @if (socialChallengeBloc.celebrationModalChallenge$ | async; as challenge) {
      <h2 class="celebration-title">{{ challenge.title }} {{ t('f_sc.Challenge') }}</h2>
      <kf-sc-social-challenge-progress
        class="celebration-paths"
        [groupGoal]="challenge.group.goal"
        [initGroupProgress]="challenge.group.status.progress.count / 2"
        [groupProgress]="challenge.group.status.progress.count"
        [individualGoal]="challenge.individual.goal"
        [initIndividualProgress]="challenge.individual.status.progress.count - 1"
        [individualProgress]="challenge.individual.status.progress.count"
        [centerPathRef]="centerPath.EMPTY"
        [isOnBackground]="true"></kf-sc-social-challenge-progress>
      <h3 class="celebration-objective">
        {{ challenge.individual.status.progress.count }} {{ challenge.objective }}
        {{ t('f_sc.Completed!') }}
      </h3>
    }
  </div>
</ng-container>
