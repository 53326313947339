<ng-container *transloco="let t">
  <div class="reminders-modal-container">
    <div class="icon">
      @if (showInitialCase) {
        <img
          src="/assets/images/night-sky-icon.png"
          [alt]="t('A diamond encompassed by 4 smaller diamonds against a backdrop of a magenta to purple gradient')" />
      }
      @if (showNotNowCase) {
        <svg-icon src="/assets/icons/notification.svg"></svg-icon>
      }
    </div>
    @if (showInitialCase) {
      <div class="title">
        {{ t('Get the most out of your Purposeful experience with notifications.') }}
      </div>
    }
    @if (showInitialCase) {
      <p class="description">
        {{
          t(
            'Purposeful users who turn on notifications are much more likely to succeed in building positive habits and reaching their goals.'
          )
        }}
      </p>
      <p class="description incognito">
        {{ t('Be aware that notifications will not work in browsers incognito / private modes.') }}
      </p>
    }
    @if (showNotNowCase) {
      <p class="description">
        {{ t('You can enable push notifications under Notifications if you change your mind.') }}
      </p>
    }
    @if (showInitialCase) {
      <div class="button-row">
        <button class="button button-pill button-reverse disable" (click)="notNow()">
          {{ t('Not now') }}
        </button>
        <button class="button button-pill button-reverse enable" (click)="enable()">
          {{ t('Turn on') }}
        </button>
      </div>
    }
    @if (showNotNowCase) {
      <div class="button-row">
        <button class="button button-pill button-reverse enable" (click)="dismissModal()">
          {{ t('Got it') }}
        </button>
      </div>
    }
  </div>
</ng-container>
