import { Component } from '@angular/core';
import { TranslocoDirective, provideTranslocoScope } from '@jsverse/transloco';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kui-ai-coach-label',
  standalone: true,
  templateUrl: './ai-coach-label.component.html',
  styleUrls: ['./ai-coach-label.component.scss'],
  imports: [TranslocoDirective],
  providers: [provideTranslocoScope('ui')],
})
export class AiCoachLabelComponent {}

export const MockAiCoachLabelComponent = MockComponent({
  selector: 'kui-ai-coach-label',
});
