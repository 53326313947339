import { NgModule } from '@angular/core';
import { ExtraOptions, mapToCanActivate, RouterModule, Routes, TitleStrategy } from '@angular/router';
import { AuthSsoLogoutComponent, CodeComponent } from '@kauth';
import { AuthenticatedGuard } from '@kp/auth/guards/authenticated.guard';
import { DeletionGuard } from '@kp/auth/guards/deletion.guard';
import { ExistingAccountGuard } from '@kp/auth/guards/existing-account.guard';
import { LegalDocumentGuard } from '@kp/auth/guards/legal-document.guard';
import { NotAuthenticatedGuard } from '@kp/auth/guards/not-authenticated.guard';
import { ProductGuard } from '@kp/auth/guards/product.guard';
import { SetPasswordGuard } from '@kp/auth/guards/set-password.guard';
import { TokenGuard } from '@kp/auth/guards/token.guard';
import { TagGuard } from '@kp/core/tags/tag.guard';
import { DataLinkGuard } from '@kp/data-link/data-link.guard';
import { EmptyRedirectGuard } from '@kp/empty-redirect.guard';
import { PulseSurveyAvailableGuard } from '@kp/shared/guards/pulse-survey-available.guard';
import { TitleStrategyDefaultService } from '@kservice';
import { TagKey } from '@ktypes/models';
import { ScreenSiteMapComponent } from '@kui';

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  // preloadingStrategy: PreloadAllModules, // uncomment to disable Lazy Loading of modules
  // enableTracing: true, // <-- debugging purposes only
};

const routes: Routes = [
  {
    path: 'token',
    // need empty children array to allow for canActivate;
    // need one of component, children or redirectTo defined per Angular spec
    children: [],
    canActivate: mapToCanActivate([DataLinkGuard]),
  },
  {
    path: 'auth/sso',
    component: CodeComponent,
  },
  {
    path: 'auth/sso/logout',
    component: AuthSsoLogoutComponent,
  },
  {
    path: 'feedback',
    loadChildren: () => import('@kp/screen-feedback/screen-feedback.module').then((mod) => mod.ScreenFeedbackModule),
    canActivate: mapToCanActivate([AuthenticatedGuard, LegalDocumentGuard]),
  },
  {
    path: 'data-link',
    loadChildren: () => import('@kp/data-link/data-link.module').then((mod) => mod.DataLinkModule),
  },
  {
    path: 'error',
    loadChildren: () => import('@kp/screen-error/screen-error.module').then((mod) => mod.ScreenErrorModule),
  },
  {
    path: 'discover',
    loadChildren: () => import('@kp/screen-discover/screen-discover.module').then((mod) => mod.ScreenDiscoverModule),
    canActivate: mapToCanActivate([AuthenticatedGuard, LegalDocumentGuard, ProductGuard]),
  },
  { path: 'explore', redirectTo: 'discover' },
  {
    // using nested routes to ensure LegalDocumentGuard runs before PulseSurveyAvailableGuard is run
    path: 'resources',
    canActivate: mapToCanActivate([
      TokenGuard,
      ProductGuard,
      LegalDocumentGuard,
      ExistingAccountGuard,
      SetPasswordGuard,
    ]),
    children: [
      {
        path: '',
        canActivate: mapToCanActivate([PulseSurveyAvailableGuard]),
        loadChildren: () =>
          import('@kp/screen-resources/screen-resources.module').then((mod) => mod.ScreenResourcesModule),
      },
    ],
  },
  {
    path: 'help',
    loadChildren: () => import('@kp/screen-help/screen-help.module').then((mod) => mod.ScreenHelpModule),
    canActivate: mapToCanActivate([AuthenticatedGuard, LegalDocumentGuard]),
  },
  {
    path: 'login',
    loadChildren: () => import('@kp/screen-login/screen-login.module').then((mod) => mod.ScreenLoginModule),
  },
  {
    path: 'logout',
    loadChildren: () => import('@kp/screen-logout/screen-logout.module').then((mod) => mod.ScreenLogoutModule),
  },
  {
    path: 'privacy',
    loadChildren: () => import('@kp/screen-privacy/screen-privacy.module').then((mod) => mod.ScreenPrivacyModule),
    canActivate: mapToCanActivate([AuthenticatedGuard, LegalDocumentGuard]),
  },
  {
    path: 'privacy-pledge',
    loadChildren: () =>
      import('@kp/screen-privacy-pledge/screen-privacy-pledge.module').then((mod) => mod.ScreenPrivacyPledgeModule),
    canActivate: mapToCanActivate([TokenGuard]),
  },
  {
    path: 'privacy-full',
    loadChildren: () =>
      import('@kp/screen-privacy-full/screen-privacy-full.module').then((mod) => mod.ScreenPrivacyFullModule),
    canActivate: mapToCanActivate([AuthenticatedGuard]),
  },
  {
    path: 'eula',
    loadChildren: () => import('@kp/screen-eula/screen-eula.module').then((mod) => mod.ScreenEulaModule),
    canActivate: mapToCanActivate([AuthenticatedGuard, LegalDocumentGuard]),
  },
  {
    path: 'progress',
    loadChildren: () => import('@kp/screen-progress/screen-progress.module').then((mod) => mod.ScreenProgressModule),
    canActivate: mapToCanActivate([AuthenticatedGuard, LegalDocumentGuard, ProductGuard]),
  },
  {
    path: 'me',
    loadChildren: () => import('@kp/screen-me/screen-me.module').then((mod) => mod.ScreenMeModule),
    canActivate: mapToCanActivate([AuthenticatedGuard, LegalDocumentGuard, ProductGuard]),
  },
  {
    path: 'mobile-app-info',
    loadChildren: () =>
      import('@kp/screen-mobile-app-info/screen-mobile-app-info.module').then((mod) => mod.ScreenMobileAppInfoModule),
    canActivate: mapToCanActivate([ProductGuard]),
  },
  {
    path: 'today',
    canActivate: mapToCanActivate([AuthenticatedGuard, LegalDocumentGuard, ProductGuard, ExistingAccountGuard]),
    loadChildren: () => import('@kp/screen-today/screen-today.module').then((mod) => mod.ScreenTodayModule),
  },
  {
    path: 'purposeful-days',
    loadChildren: () =>
      import('@kp/screen-daily-challenge/screen-daily-challenge.module').then((mod) => mod.ScreenDailyChallengeModule),
    canActivate: mapToCanActivate([AuthenticatedGuard, LegalDocumentGuard, ProductGuard]),
  },
  {
    path: 'pulse_survey',
    loadChildren: () =>
      import('@kp/screen-pulse-survey/screen-pulse-survey.module').then((mod) => mod.ScreenPulseSurveyModule),
    canActivate: mapToCanActivate([DataLinkGuard, LegalDocumentGuard]),
  },
  {
    path: 'quest',
    loadChildren: () => import('@kp/screen-quest/screen-quest.module').then((mod) => mod.ScreenQuestModule),
    canActivate: mapToCanActivate([AuthenticatedGuard]),
  },
  {
    path: 'question_set',
    loadChildren: () => import('@kp/dialogue/dialogue.module').then((mod) => mod.DialogueModule),
    canActivate: mapToCanActivate([TokenGuard]),
  },
  {
    path: 'dialogue',
    loadChildren: () => import('@kp/dialogue/dialogue.module').then((mod) => mod.DialogueModule),
    canActivate: mapToCanActivate([TokenGuard]),
  },
  {
    path: 'reminders',
    loadChildren: () => import('@kp/screen-reminders/screen-reminders.module').then((mod) => mod.ScreenRemindersModule),
    canActivate: mapToCanActivate([AuthenticatedGuard, ProductGuard]),
  },
  {
    path: 'report',
    loadChildren: () => import('@kp/screen-report/screen-report.module').then((mod) => mod.ScreenReportModule),
    canActivate: mapToCanActivate([TokenGuard, LegalDocumentGuard]),
    title: 'Report',
  },
  {
    path: 'report/:reportId',
    loadChildren: () => import('@kp/screen-report/screen-report.module').then((mod) => mod.ScreenReportModule),
    canActivate: mapToCanActivate([TokenGuard, LegalDocumentGuard]),
  },
  {
    path: 'thank_you',
    loadChildren: () => import('@kp/screen-thank-you/screen-thank-you.module').then((mod) => mod.ScreenThankYouModule),
  },
  {
    path: 'unsubscribe',
    loadChildren: () =>
      import('@kp/screen-email-subscription/screen-email-subscription.module').then(
        (mod) => mod.ScreenEmailSubscriptionModule
      ),
  },
  {
    path: 'cards/take-action',
    loadChildren: () =>
      import('@kp/screen-take-action/screen-take-action.module').then((mod) => mod.ScreenTakeActionModule),
    canActivate: mapToCanActivate([AuthenticatedGuard, LegalDocumentGuard]),
  },
  {
    path: 'checkout/success/reactivate/:session_id',
    redirectTo: 'today',
  },
  {
    path: 'checkout/success/onboarding/mobile/:session_id',
    pathMatch: 'full',
    redirectTo: 'welcome/mobile-app/interstitial',
  },
  {
    path: 'checkout/success/onboarding/:session_id',
    pathMatch: 'full',
    redirectTo: 'welcome/mobile-app/interstitial',
  },
  {
    path: 'checkout/reactivate/cancel',
    redirectTo: 'login',
  },
  {
    path: 'checkout/onboarding/cancel',
    redirectTo: 'welcome',
  },
  {
    path: 'cards/actions',
    redirectTo: 'cards/take-action',
  },
  {
    path: 'cards/quests',
    redirectTo: 'cards/take-action',
  },
  {
    path: 'cards/:category',
    loadChildren: () =>
      import('@kp/screen-card-category/screen-card-category.module').then((mod) => mod.ScreenCardCategoryModule),
    canActivate: mapToCanActivate([AuthenticatedGuard, LegalDocumentGuard]),
  },
  {
    path: 'deletion',
    loadChildren: () =>
      import('@kp/settings/screen-deletion-requested/screen-deletion-requested.module').then(
        (mod) => mod.ScreenDeletionRequestedModule
      ),
    canActivate: mapToCanActivate([AuthenticatedGuard]),
  },
  {
    path: 'deletion-confirmation',
    loadChildren: () =>
      import('@kp/settings/screen-deletion-confirmation/screen-deletion-confirmation.module').then(
        (mod) => mod.ScreenDeletionConfirmationModule
      ),
    canActivate: mapToCanActivate([AuthenticatedGuard]),
  },
  {
    path: 'delete',
    loadChildren: () =>
      import('@kp/screen-account-deletion/screen-account-deletion.module').then(
        (mod) => mod.ScreenAccountDeletionModule
      ),
    canActivate: mapToCanActivate([DeletionGuard]),
  },
  {
    path: 'settings',
    loadChildren: () => import('@kp/settings/screen-settings.module').then((mod) => mod.ScreenSettingsModule),
    canActivate: mapToCanActivate([AuthenticatedGuard, LegalDocumentGuard]),
  },
  {
    path: 'challenges',
    loadChildren: () => import('@kp/challenges/challenges.module').then((mod) => mod.ChallengesModule),
    canActivate: mapToCanActivate([AuthenticatedGuard, LegalDocumentGuard, ProductGuard, TagGuard]),
    data: {
      requiredTags: [{ tag: TagKey.social_challenges_enabled, redirectUrl: '/error' }],
    },
  },
  {
    path: 'invite',
    title: 'Invite',
    loadChildren: () => import('@kp/screen-invites/screen-invites.module').then((mod) => mod.ScreenInvitesModule),
    canActivate: mapToCanActivate([AuthenticatedGuard, LegalDocumentGuard]),
  },
  {
    path: 'site-map',
    component: ScreenSiteMapComponent,
    title: 'Site Map',
  },
  {
    path: 'welcome',
    loadChildren: () => import('@kp/onboarding/onboarding.module').then((mod) => mod.OnboardingModule),
  },
  {
    path: 'app',
    // need empty children array to allow for canActivate;
    // need one of component, children or redirectTo defined per Angular spec
    children: [],
    canActivate: mapToCanActivate([DataLinkGuard, EmptyRedirectGuard]),
  },
  {
    path: 'web',
    // need empty children array to allow for canActivate;
    // need one of component, children or redirectTo defined per Angular spec
    children: [],
    canActivate: mapToCanActivate([DataLinkGuard, EmptyRedirectGuard]),
  },
  {
    path: ':groupCode/:anythingElse',
    pathMatch: 'full',
    redirectTo: 'welcome/error',
  },
  {
    path: ':groupCode',
    loadChildren: () => import('@kp/screen-initial/screen-initial.module').then((mod) => mod.ScreenInitialModule),
    canActivate: mapToCanActivate([NotAuthenticatedGuard, DataLinkGuard]),
  },
  {
    path: '',
    pathMatch: 'full',
    // need empty children array to allow for canActivate;
    // need one of component, children or redirectTo defined per Angular spec
    children: [],
    canActivate: mapToCanActivate([DataLinkGuard, EmptyRedirectGuard]),
  },
  { path: '**', redirectTo: '/error' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
  providers: [{ provide: TitleStrategy, useClass: TitleStrategyDefaultService }],
})
export class AppRoutingModule {}
