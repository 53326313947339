<ng-container *transloco="let t">
  <div class="purposeful-days-modal-container">
    <h2 class="title">{{ t('Purposeful Day') }}</h2>
    <div class="subtitle">{{ t('complete') }}</div>
    <div class="items">
      <div class="line-item show-up">
        <div class="diamond-icon show-up">
          <div class="diamond-icon-inner show-up"></div>
        </div>
        <h3 class="show-up">{{ t('Show up') }}</h3>
      </div>
      <div class="line-item check-in">
        <div class="diamond-icon check-in">
          <div class="diamond-icon-inner check-in"></div>
        </div>
        <h3 class="check-in">{{ t('Check in') }}</h3>
      </div>
      <div class="line-item take-action">
        <div class="diamond-icon take-action">
          <div class="diamond-icon-inner take-action"></div>
        </div>
        <h3 class="take-action">{{ t('Take action') }}</h3>
      </div>
    </div>
    @if (streakBloc.streak$ | async; as streakStatus) {
      <div class="streak">
        <svg-icon src="/assets/icons/streak-flame.svg"></svg-icon>
        <h3 class="streak-text" (animationstart)="onAnimationStart()">
          @if (!streakStatus?.data?.current || streakStatus?.data?.current - 1 < 0) {
            0
          } @else {
            {{ t('currentLength-day streak!', { currentLength: streakStatus?.data?.current }) }}
          }
        </h3>
      </div>
    }
  </div>
</ng-container>
