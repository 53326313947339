<ng-container *transloco="let t">
  <h2 class="title">{{ title }}</h2>
  <p class="description">
    {{ info }}
  </p>
  <div class="button-column">
    <button class="button button-reverse button-pill button-close" (click)="closeClicked.emit(false)">
      {{ t('ui.Got It') }}
    </button>
  </div>
</ng-container>
