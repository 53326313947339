<ng-container *transloco="let t">
  <div class="action-container">
    <h3 class="action-topic">
      @switch (cardType) {
        @case (cardModalType.action) {
          {{ t('Take Action') }}
        }
        @case (cardModalType.quest) {
          {{ t('Continue Quests') }}
        }
      }
      on Today
    </h3>
    <p class="action-content">
      @switch (cardType) {
        @case (cardModalType.action) {
          {{
            t(
              'Youve committed to trying your first Action! See how it goes, then come back to Today to check it off as complete.'
            )
          }}
        }
        @case (cardModalType.quest) {
          {{ t('Youve committed to starting your first Quest! Find it on Today under Take Action to make progress.') }}
        }
      }
    </p>
    <a class="today-link" routerLink="/today" (click)="closeClicked.emit()">{{ t('Go to Today') }}</a>
    <button class="button button-reverse button-pill action-button" (click)="closeClicked.emit()">
      {{ t('Got It') }}
    </button>
  </div>
</ng-container>
