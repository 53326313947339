<ng-container *transloco="let t">
  @if (accountCreationModelVisible) {
    <kui-modal
      [isOpen]="accountCreationModelVisible"
      (modalCloseMethod)="isAccountCreationModalOpen($event)"
      [canDismiss]="!requireAccountCreation"
      type="info">
      <kp-account-creation-modal
        (closeClicked)="isAccountCreationModalOpen($event)"
        [canDismiss]="!requireAccountCreation"></kp-account-creation-modal>
    </kui-modal>
  }

  <div class="banner-container">
    <div class="banner-content">
      <div class="modal-content">
        {{ t('Welcome to Resourceful!') }}
        <button class="learn-more" (click)="learnMore()">{{ t('Learn More') }}</button>
      </div>
      <a
        routerLink="/welcome/signup"
        class="button button-pill button-reverse account-creation"
        data-test="create-account-link"
        (click)="createAccount()">
        {{ t('Create Account') }}
      </a>
    </div>
  </div>
</ng-container>
