<ng-container *transloco="let t">
  <label
    class="select-bordered {{ config.color }} {{ config.borderColor }}"
    [ngClass]="{ 'has-lead-icon': config.leadIcon }">
    <select class="{{ config.color }}" id="options" (change)="optionsChanged($event.target)">
      @for (option of options; track option) {
        <option [value]="option.value" [selected]="option.selected">
          {{ option.name }}
        </option>
      }
    </select>
    @if (config.leadIcon) {
      <svg-icon
        class="leading-icon nav-icon"
        src="{{ config.leadIcon }}"
        [title]="t('ui.semanticLabel Dropdown', { semanticLabel })"></svg-icon>
    }
    <div class="drop-icon {{ config.color }}">
      <svg-icon
        class="drop-chevron nav-icon drop-icon chevron"
        src="/assets/shared/images/icons/chevron-right.svg"></svg-icon>
    </div>
  </label>
</ng-container>
