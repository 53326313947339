<ng-container *transloco="let t">
  @if (navItem.type === navItemType.standard) {
    <a
      [routerLink]="navItem.link"
      (click)="navItemClicked.emit()"
      class="header-item"
      routerLinkActive="nav-active"
      ariaCurrentWhenActive="page">
      @if (state === navImageState.loaded && image?.urlSmallest) {
        <img
          class="image-icon"
          [src]="image?.urlSmallest | bypassUrlSafety"
          [alt]="
            t('Uploaded image, original filename was originalFilename', { originalFilename: image?.originalFilename })
          " />
      }
      @if (!image?.urlSmallest && navItem.icon) {
        <svg-icon [src]="navItem.icon"></svg-icon>
      }
      <div class="header-title">
        {{ t(navItem.title) }}
      </div>
    </a>
  }

  @if (navItem.type === navItemType.quickActions) {
    <kp-quick-actions
      [quickActionsOpen]="quickActionsOpen"
      (quickActionsToggled)="quickActionsToggled.emit($event)"></kp-quick-actions>
  }
</ng-container>
