<ng-container *transloco="let t">
  <progress
    class="progress-bar"
    [ngClass]="{ primary: hasPrimaryColors }"
    [max]="displayMax"
    [value]="displayValue"></progress>
  @if (displayAsPercent) {
    <div class="percentage">{{ t('percent complete', { percent: displayValue }) }}</div>
  }
</ng-container>
