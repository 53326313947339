import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-permission-dialog',
  templateUrl: './permission-dialog.component.html',
  styleUrls: ['./permission-dialog.component.scss'],
})
export class PermissionDialogComponent {
  @Input() canEnable = false;
  @Input() description: string; // NOTE: Translate before passing in
  @Output() enableButtonClicked = new EventEmitter();

  enableNotifications() {
    this.enableButtonClicked.emit();
  }
}

export const MockPermissionDialogComponent = MockComponent({
  selector: 'kp-permission-dialog',
  inputs: ['canEnable', 'description'],
});
