import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { WINDOW_PROVIDERS } from './window.service';

@NgModule({
  imports: [CommonModule, TranslocoModule],
  providers: [WINDOW_PROVIDERS],
})
export class ServiceModule {}
