import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink } from '@angular/router';
import { provideTranslocoScope, TranslocoDirective, TranslocoModule } from '@jsverse/transloco';
import { TranslatePipe } from '@kf-loc';
import { TooltipVariations } from '@ktypes/props';
import { UiModule } from '@kui';
import { UtilsModule } from '@kutil';
import { popperVariation, provideTippyConfig, TippyDirective, tooltipVariation } from '@ngneat/helipopper';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ModalSocialChallengeCelebrateComponent } from './components/modal-challenge-celebrate/modal-social-challenge-celebrate.component';
import { SocialChallengeDetailProgressComponent } from './components/social-challenge-detail-progress/social-challenge-detail-progress.component';
import { SocialChallengeLabelComponent } from './components/social-challenge-label/social-challenge-label.component';
import { SocialChallengeMenuComponent } from './components/social-challenge-menu/social-challenge-menu.component';
import { SocialChallengeProgressComponent } from './components/social-challenge-progress/social-challenge-progress.component';
import { SocialChallengeComponent } from './components/social-challenge/social-challenge.component';

@NgModule({
  declarations: [
    ModalSocialChallengeCelebrateComponent,
    SocialChallengeDetailProgressComponent,
    SocialChallengeLabelComponent,
    SocialChallengeMenuComponent,
    SocialChallengeProgressComponent,
    SocialChallengeComponent,
  ],
  imports: [
    CommonModule,
    AngularSvgIconModule,
    RouterLink,
    TippyDirective,
    TranslatePipe,
    TranslocoDirective,
    TranslocoModule,
    UiModule,
    UtilsModule,
  ],
  exports: [
    ModalSocialChallengeCelebrateComponent,
    SocialChallengeDetailProgressComponent,
    SocialChallengeLabelComponent,
    SocialChallengeMenuComponent,
    SocialChallengeProgressComponent,
    SocialChallengeComponent,
  ],
  providers: [
    provideTippyConfig({
      defaultVariation: 'primary',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
        primary: TooltipVariations.primary,
        primaryInfo: TooltipVariations.primaryInfo,
        challengeMenu: TooltipVariations.challengeMenu,
      },
      popperOptions: {
        strategy: 'fixed',
      },
    }),
    provideTranslocoScope({
      scope: 'features.social-challenges',
      alias: 'f_sc',
    }),
  ],
})
export class SocialChallengesModule {}
