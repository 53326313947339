<ng-container *transloco="let t">
  @if (challenge?.status.state === challengeState.upcoming && challenge?.startDate) {
    <kui-label-flag [color]="color" [backgroundColor]="backgroundColor">
      <svg-icon src="/assets/icons/clock.svg"></svg-icon>
      {{ t('f_sc.Starts') }} {{ challenge?.startDate | date: dateFormat }}
    </kui-label-flag>
  }
  @if (challenge?.status.state === challengeState.active && !isDetail) {
    <kui-label-flag [color]="color" [backgroundColor]="backgroundColor">
      <svg-icon src="/assets/icons/clock.svg"></svg-icon>
      {{ challenge?.status.daysLeft | number }} {{ t('f_sc.days left') }}
    </kui-label-flag>
  }
  @if (challenge?.status.state === challengeState.active && isDetail) {
    <kui-label-flag [color]="color" [backgroundColor]="backgroundColor">
      <svg-icon src="/assets/icons/clock.svg"></svg-icon>
      {{ t('f_sc.Ends') }} {{ challenge?.endDate | date: dateFormat }}
    </kui-label-flag>
  }
  @if (challenge?.status.state === challengeState.ended && challenge?.endDate) {
    <kui-label-flag [color]="color" [backgroundColor]="backgroundColor">
      <svg-icon src="/assets/icons/clock.svg"></svg-icon>
      {{ t('f_sc.Ended') }} {{ challenge?.endDate | date: dateFormat }}
    </kui-label-flag>
  }
</ng-container>
